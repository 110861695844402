import { onUserActivity } from '../../external_modules/analytics-utils-activity/activity-utils-activity';
import {
  setActivity,
  setActivetime,
  setIdletime,
  sdkBehaviorConfig,
  getActivityTime,
} from '../../config/sdkConfig';
import Log from '../../utils/logger';

export const activityTimeTracker = () => {
  const idleListener = onUserActivity({
    timeout: sdkBehaviorConfig.userActivityOnPageTimeoutMilis,
    throttle: sdkBehaviorConfig.userActivityOnPageTimeoutThrottleMilis,
    onIdle: (activeTime) => captureActiveTime(activeTime),
    onWakeUp: (idleTime) => captureIdleTime(idleTime),
  });
};

export const captureActiveTime = (activeTime) => {
  setActivetime(activeTime);
  // const activityPair = getActivityTime();
  // Log.debug(
  //   `USER IDLE after being active for ${activeTime}s
  //   Total active: ${activityPair.active}s, total idle ${activityPair.idle}s`,
  // );
};

export const captureIdleTime = (idleTime) => {
  setIdletime(idleTime);
  // const activityPair = getActivityTime();
  // Log.debug(
  //   `USER ACTIVE after idling for ${idleTime}s
  //   Total active: ${activityPair.active}s, total idle ${activityPair.idle}s`,
  // );
};

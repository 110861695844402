export function getMessageQueue() {
  return dpaMessageTemplate;
}

const dpaMessageTemplate = {
  Header: {
    TxID: '',
    CaptureEnv: '',
    ResponseType: '',
    DeviceInfo: {},
  },
  Payload: {
    Metrics: [],
  },
};

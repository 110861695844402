import {
  searchForOriginId,
  searchForOriginTag,
  searchForClosestSyntheticTag,
  constructReplacementForNativeElement,
  checkForAutoCapture,
  searchForClosestTextNode,
} from '../algorithms';
import {
  startPerformanceMeasure,
  endPerformanceMeasure,
} from '../../utils/performance';
import { processChangeEvents } from './changeEventHandler';
import { a, button, input } from '../../utils/constants';
import {
  filterCustomData,
  removeEmptyArgs,
  sanitizeUserInput,
} from '../../utils/functions';
import { sendEventUnfiltered } from '../../publish/sendCapturedEvents';
import {
  NATIVELY_SUPPORTED_HTML_TAGS,
  DPA_BUTTON_CLICK,
  DPA_LINK_CLICK,
} from '../../utils/constants';
import Log from '../../utils/logger';

export function clickEventHandler(anEvent) {
  const startPerfMarker = startPerformanceMeasure(
    'process-click-event',
    anEvent,
  );

  // body of the function
  if (checkForAutoCapture(anEvent)) return;
  let eventSourceTag = anEvent.target;
  let eventInfo = {};
  eventInfo.origin_id = searchForOriginId({ event: anEvent });
  eventInfo.tm_sinceload = Math.round(anEvent.timeStamp);
  eventInfo.originHTMLTag = eventSourceTag.tagName.toLowerCase();

  if (NATIVELY_SUPPORTED_HTML_TAGS.indexOf(eventInfo.originHTMLTag) === -1) {
    // 1 if no native HTML element search up for one
    let foundActionableElement = searchForOriginTag(anEvent);
    // 2 if we find a native element
    if (foundActionableElement !== null) {
      eventInfo.origin_id = foundActionableElement?.id;
      eventSourceTag = foundActionableElement;
    } else {
      // 3 if no element found search for a synthetic tag
      let foundWrappedSyntheticElement = searchForClosestSyntheticTag(anEvent);
      if (Object.keys(foundWrappedSyntheticElement).length === 0) return;
      // for Select menu - prevent double reporting:
      if (foundWrappedSyntheticElement.isExpanded) return;
      //capture the nearest role to the actioned element
      eventInfo.roleName = foundWrappedSyntheticElement?.role;
      // 4 if synthetic tag found tests
      if (foundWrappedSyntheticElement !== null) {
        // construct replacement for native html tag
        eventSourceTag = constructReplacementForNativeElement(
          foundWrappedSyntheticElement,
        );
        let syntheticTag = eventSourceTag.tagName;
        // Should this be passed to change event processing
        if (
          syntheticTag === 'select' ||
          syntheticTag === 'textarea' ||
          syntheticTag === 'input'
        ) {
          // this is a change event, process as such
          processChangeEvents({ ...eventInfo, ...eventSourceTag });
          return;
        }
        if (syntheticTag === 'a') {
          eventInfo.eventTag = eventSourceTag;
          eventInfo.eventTagType = eventSourceTag?.type;
          eventInfo.eventTagName = eventSourceTag.tagName;
          eventInfo.eventTagTitle = eventSourceTag?.title;
          eventInfo.eventTagForm = eventSourceTag?.form;
          eventInfo.eventTagDataset = eventSourceTag?.dataset;
          let linkText = searchForClosestTextNode(anEvent);
          eventInfo.eventTagText = linkText;
          processClickEvents(eventInfo);
          return;
        }
      } else if (foundWrappedSyntheticElement === null) {
        // if no synthetic tag reset for safety
        eventSourceTag = {
          type: '',
          tagName: '',
          title: '',
          form: '',
          dataset: '',
        };
        Log.debug(
          'failed to find native or synthetic tag as origin of the event',
        );
        return;
      }
    }
  }

  eventInfo.eventTag = eventSourceTag;
  eventInfo.eventTagType = eventSourceTag.type;
  processClickEvents(eventInfo);
  endPerformanceMeasure(startPerfMarker);
}

function processClickEvents(anEventInfo) {
  let args = {};
  let messageBodyMap = {};
  messageBodyMap.eventTimeStamp = new Date();
  let eventSourceTag = anEventInfo.eventTag;
  const HTMLTagName = eventSourceTag.tagName?.toLowerCase();
  args['event.execute.tm_since_load'] = anEventInfo.tm_sinceload;

  switch (HTMLTagName) {
    case button: {
      if (anEventInfo.eventTagType === 'reset') break;
      let interaction_code = DPA_BUTTON_CLICK;
      args['event.execute.origin_id'] = anEventInfo.origin_id;
      if (anEventInfo?.form) {
        args['event.execute.form_id'] = anEventInfo.form?.id;
      }
      args['event.execute.origin_role'] = anEventInfo.roleName
        ? anEventInfo.roleName
        : HTMLTagName;
      args['event.execute.origin_type'] = anEventInfo.eventTagType;
      args['event.execute.origin_label'] = anEventInfo.eventTag.innerText;
      args = removeEmptyArgs(args);
      let customData = filterCustomData(anEventInfo.eventTag.dataset);

      messageBodyMap = {
        ...messageBodyMap,
        interactionCode: interaction_code,
      };

      // console.log('button tag args: ', args);
      // console.log('button tag info: ', anEventInfo);
      // console.log('button tag custom data: ', customData);

      sendEventUnfiltered(anEventInfo, 'Click event handler', messageBodyMap, {
        ...args,
        ...customData,
      });
      break;
    }

    case a: {
      let interaction_code = DPA_LINK_CLICK;
      // if link is synthetic there is no href, use location
      const currHref = anEventInfo.eventTag.href
        ? new URL(anEventInfo.eventTag.href)
        : new URL(window.location);
      args['event.execute.target_external'] =
        currHref.hostname === window.location.hostname ? false : true;
      args['event.execute.target_url'] = currHref?.origin + currHref?.pathname;
      args['event.execute.target_hash'] = currHref.hash;
      args['event.execute.origin_id'] = anEventInfo.origin_id;
      args['event.execute.origin_label'] =
        anEventInfo.eventTag.innerText?.slice(0, 63);
      args['event.execute.origin_role'] = anEventInfo.originHTMLTag;

      let customData = filterCustomData(anEventInfo.eventTag.dataset);
      args = removeEmptyArgs(args);

      messageBodyMap = {
        ...messageBodyMap,
        interactionCode: interaction_code,
      };

      // console.log('anchor tag args: ', args);
      // console.log('anchor tag info: ', anEventInfo);
      // console.log('anchor tag custom data: ', customData);

      sendEventUnfiltered(anEventInfo, 'Click event handler', messageBodyMap, {
        ...args,
        ...customData,
      });
      break;
    }

    case input: {
      if (
        !(
          anEventInfo.eventTagType === 'button' ||
          anEventInfo.eventTagType === 'submit'
        )
      ) {
        break;
      }
      // For input type button or submit ONLY (reset is its own event)
      let interaction_code = DPA_BUTTON_CLICK;
      messageBodyMap.eventTimeStamp = new Date();
      args['event.execute.origin_id'] = anEventInfo.origin_id;
      args['event.execute.origin_label'] = anEventInfo.eventTag.value;

      if (anEventInfo.eventTag.form?.id) {
        args['event.execute.form_id'] = sanitizeUserInput(
          anEventInfo.eventTag.form?.id,
        );
      }
      args['event.execute.origin_type'] = anEventInfo.eventTagType;
      args['event.execute.origin_role'] = anEventInfo.originHTMLTag;

      let customData = filterCustomData(anEventInfo.eventTag.dataset);
      args = removeEmptyArgs(args);

      messageBodyMap = {
        interactionCode: interaction_code,
      };

      // console.log('input tag args: ', args);
      // console.log('input tag info: ', anEventInfo);
      // console.log('input tag custom data: ', customData);

      sendEventUnfiltered(anEventInfo, 'Click event handler', messageBodyMap, {
        ...args,
        ...customData,
      });
      break;
    }

    default: {
      Log.warn(`${HTMLTagName} not registered in clickEventHandler`);
      break;
    }
  }
}

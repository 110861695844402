import {
  getPageSession,
  getSession,
  setPageSession,
} from '../external_modules/analytics-util-session';
import {
  setUserSession,
  setTabSession as setTabSessionObject,
  setAssetLoadSession,
  getTabSession,
  sdkBehaviorConfig,
} from '../config/sdkConfig';
import { sendEventUnfiltered } from '../publish/sendCapturedEvents';

import { APPLICATION_LAUNCH } from '../utils/constants';
import Log from '../utils/logger';
import * as packageJson from '../../package.json';
import { loadTelemetry } from './nativeBehaviorEventHandlers';

export const manageUserSession = () => {
  const updatedUserSessionObject = getSession(
    sdkBehaviorConfig.sessionTimeoutMinutes,
  );
  const updatedTabSession = getTabSession();

  setUserSession(updatedUserSessionObject);
  setTabSessionObject(updatedTabSession);
  return updatedUserSessionObject;
};

export const manageAssetLoadSession = (aNewAssetLoadSession = null) => {
  let updatedAssetLoadSession = aNewAssetLoadSession;

  if (updatedAssetLoadSession === null) {
    updatedAssetLoadSession = getPageSession();
  }

  setAssetLoadSession(updatedAssetLoadSession);
  return updatedAssetLoadSession;
};

const createOrRestoreSessionState = () => {
  const updatedUserSession = manageUserSession();
  const updatedAssetLoadSession = manageAssetLoadSession();

  if (updatedUserSession.isNew) {
    //Send a Application Launch.
    // TODO:: If within a JPMM container then do not send this event.
    Log.debug(
      `NEW SESSION id: ${updatedUserSession.id}, expires @${updatedUserSession.expiresAt}`,
    );

    const messageBodyMap = {
      interactionCode: APPLICATION_LAUNCH,
    };
    sdkBehaviorConfig.isaNewApplicationLaunch = true;
    const assetLoad = {};
    loadTelemetry(assetLoad);
    let eventAttributesMap = {
      'protocol.capture.tool_name': 'js-sdk',
      'protocol.capture.tool_version': packageJson.version,
    };
    sendEventUnfiltered(null, APPLICATION_LAUNCH, messageBodyMap, {
      ...assetLoad,
      ...eventAttributesMap,
    });
  } else {
    Log.debug(
      `OLD session with id: ${updatedUserSession.id}, created: ${updatedUserSession.createdAt}, expires: ${updatedUserSession.expiresAt}`,
    );
  }
  if (updatedAssetLoadSession.isNew) {
    Log.debug(
      `NEW instance of page: ${window.self.location.href}, assetLoadID: ${updatedAssetLoadSession.id}`,
    );
  } else {
    Log.debug(
      `OLD instance of page: ${window.self.location.href} - ${updatedAssetLoadSession.id}`,
    );
  }
};

export default createOrRestoreSessionState;

import createOrRestoreSessionState from '../analytics/sessionManager';
import { activityTimeTracker } from '../analytics/algorithms/activityTimeManager';
import { initializeAnalyticsEventListeners } from '../analytics/nativeBehaviorEventHandlers';
import {
  refreshIdaToken,
  setGlobalJourney,
} from '../publish/sendCapturedEvents';
import {
  isPublicUnAuthenticatedRoute,
  isSiteminderAuthenticatedRoute,
  //   isMetricsUnAuthenticatedRoute,
} from '../utils/validation';
import {
  //   UNAUTHENTICATED_EXT,
  PUBLIC_UNAUTHENTICATED_EXT,
  URL_SEPARATOR,
  URL_CONTEXT,
  URL_ADFS_REFRESH,
  URL_PING_EXT,
  URL_CONTEXT_SITEMINDER,
} from '../utils/constants';

/**
 * Initializes the plugin :: Analytics
 * @param {object} config
 */
const initializeAnalytics = (config) => {
  try {
    let name = 'dpanalytics';
    let isIframe = window.self !== window.top;
    const url = new URL(config.EndPoint);
    let journeyurl =
      url.protocol +
      URL_SEPARATOR +
      URL_SEPARATOR +
      url.hostname +
      URL_SEPARATOR +
      (!config.isIda
        ? (isSiteminderAuthenticatedRoute(config.EndPoint)
            ? URL_CONTEXT_SITEMINDER + URL_SEPARATOR
            : '') +
          URL_CONTEXT +
          URL_SEPARATOR +
          (isPublicUnAuthenticatedRoute(config.EndPoint)
            ? PUBLIC_UNAUTHENTICATED_EXT + URL_SEPARATOR
            : '')
        : '') +
      URL_PING_EXT;
    if (config.isIda) {
      let refreshEndpoint =
        url.protocol +
        URL_SEPARATOR +
        URL_SEPARATOR +
        url.hostname +
        URL_ADFS_REFRESH;
      // console.debug("refresh endpoint::"+refreshEndpoint);
      refreshIdaToken(refreshEndpoint, journeyurl,config.idaRefreshInterval);
    } else {
      //Call the Global ID cookie call
      setGlobalJourney(journeyurl);
    }

    if (isIframe) {
      //TODO:: Identify if in DP containers ( JPMM, DPD or ILP ) name the window scope variable accordingly
      //TODO:: Send application Launch based on the scope ( within container or with container)
      name = 'dpanalytics-iframe';
    }

    if (config.isConfigValid && config.isCaptureEnabled) {
      createOrRestoreSessionState();
      initializeAnalyticsEventListeners();
      activityTimeTracker();
    }
  } catch (error) {
    console.error(`dpAnalytics exiting: ${error}`);
  }
};

export default initializeAnalytics;

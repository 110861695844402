import inBrowser from './inBrowser';
import parseParams from './paramsParse';
import isExternalReferrer from './isExternalReferrer';

const googleKey = 'google';

/**
 * @typedef {{
 *  campaign: string,
 *  referrer?: string,
 * } & DomainObject & Object.<string, any>} ReferrerObject
 */

/**
 * Checks a given url and parses referrer data
 * @param  {String} [referrer] - (optional) referring URL
 * @param  {String} [currentUrl] - (optional) the current url
 * @return {ReferrerObject}     [description]
 */
export default function parseReferrer(referrer, currentUrl) {
  if (!inBrowser) return false;
  // default referral data
  let refData = {
    source: '(direct)',
    medium: '(none)',
    campaign: '(not set)',
  };
  // Add raw ref url if external
  if (referrer && isExternalReferrer(referrer)) {
    refData.referrer = referrer;
  }

  const domainInfo = parseDomain(referrer);
  // Read referrer URI and infer source
  if (domainInfo && Object.keys(domainInfo).length) {
    refData = Object.assign({}, refData, domainInfo);
  }

  // Read URI params and use set utm params
  const params = parseParams(currentUrl);
  const paramKeys = Object.keys(params);
  if (!paramKeys.length) {
    return refData;
  }

  // set campaign params off GA matches
  const gaParams = paramKeys.reduce((acc, key) => {
    // match utm params & dclid (display) & gclid (cpc)
    if (key.match(/^utm_/)) {
      acc[`${key.replace(/^utm_/, '')}`] = params[key];
    }
    // https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters
    // dclid - cpc Cost-Per-Thousand Impressions
    // gclid - cpc Cost per Click
    if (key.match(/^(d|g)clid/)) {
      acc['source'] = googleKey;
      acc['medium'] = params.gclid ? 'cpc' : 'cpm';
      acc[key] = params[key];
    }
    return acc;
  }, {});

  return Object.assign({}, refData, gaParams);
}

/**
 * @typedef {{
 *  source: string,
 *  medium: string,
 * }} DomainObject
 */

/**
 * Client side domain parser for determining marketing data.
 * @param  {String} referrer - ref url
 * @return {DomainObject | boolean}
 */
export function parseDomain(referrer) {
  if (!referrer || !inBrowser) return false;
  let referrerToExtract;
  try {
    referrerToExtract = new URL(referrer);
    referrerToExtract.href = referrer;
  } catch (error) {
    console.error(err);
  }

  const medium = !isExternalReferrer(referrer) ? 'internal' : 'referral';
  return {
    source: referrerToExtract.hostname,
    medium: medium,
  };
}

/**
 * @return {string}
 */

import { uuid as UUID } from '../external_modules/analytics-utils/index';

export function uuid() {
  const u = UUID();
  return u;
}

import UAParser from 'ua-parser-js';
import {
  getConfig,
  sdkBehaviorConfig,
  getAssetLoadSession,
  getUserSession,
} from '../config/sdkConfig';

import { isUnAuthenticatedRoute } from '../utils/validation';

import { getMessageQueue } from '../data/messageQueue';
import {
  EMPTY,
  ANONYMOUS_USER,
  URL_JPMORGAN_PING_PROD,
  URL_JPMORGAN_PING_UAT,
} from '../utils/constants';
import {
  validateInteractionCode,
  toTitleCase,
  flattenObject,
} from '../utils/functions';
import pkg from '../../package.json';
import { validateMessageBody, isProd, isInternal } from '../utils/validation';
import { uuid } from '../utils/public';

import {
  startPerformanceMeasure,
  endPerformanceMeasure,
} from '../utils/performance';
import Log from '../utils/logger';

export const refreshIdaToken = (
  refreshEndpoint,
  journeyUrl,
  idaRefreshInterval,
) => {
  let request = new Request(refreshEndpoint, {
    method: 'GET',
    body: null,
    credentials: 'include',
    mode: 'no-cors',
  });
  fetch(request)
    .then(() => {
      setGlobalJourney(journeyUrl);
    })
    .catch((error) => {
      Log.error(error);
    });

  setInterval(() => {
    fetch(request).catch((error) => Log.error(error));
  }, idaRefreshInterval);
};

export const createEvent = (
  anEvent,
  aCustomEventValue,
  aMessageBodyMap = {},
  anEventAttributesMap = {},
) => {
  let messageBody = {};
  let eventAttributes = { ...anEventAttributesMap };
  let {
    ApplicationCode,
    ApplicationVersion,
    isProd,
    SessionID,
    Platform,
    HostedBy,
    active,
    idle,
  } = getConfig();

  // Attributes that could be set before createEvent and must be preserved, not overwritten
  messageBody.interactionCode = aMessageBodyMap?.interactionCode;

  // Note: assetAddress for react applications in SPAPageChange events
  // are set @nativeBehaviorEventHandlers > reactRouteChangeEventHandler
  messageBody.assetAddress = aMessageBodyMap?.assetAddress
    ? aMessageBodyMap.assetAddress
    : window.location?.origin +
      window.location?.pathname +
      window.location?.hash;

  // COMPONENTID //
  if (aMessageBodyMap?.componentId) {
    messageBody.componentId = aMessageBodyMap.componentId;
  }

  if (aMessageBodyMap?.interactionDescriptor) {
    messageBody.interactionDescriptor =
      aMessageBodyMap.interactionDescriptor.substring(0, 200);
  }

  messageBody.assetTitle = aMessageBodyMap?.assetTitle
    ? aMessageBodyMap.assetTitle
    : document.title;

  messageBody.assetTitle = messageBody.assetTitle?.slice(-120);

  if (window?.document?.referrer) {
    try {
      let referrerUrl = new URL(window.document.referrer);
      messageBody.referrerAddress = referrerUrl.href + referrerUrl.hash;
    } catch (e) {
      messageBody.referrerAddress = window.document.referrer;
    }
  }

  if (aMessageBodyMap?.eventSourceComponentCode) {
    messageBody.eventSourceComponentCode =
      aMessageBodyMap.eventSourceComponentCode;
  }

  aMessageBodyMap.eventTimeStamp = aMessageBodyMap?.eventTimeStamp
    ? aMessageBodyMap.eventTimeStamp
    : new Date();
  messageBody.dateCapturedUTC = aMessageBodyMap.eventTimeStamp.toISOString();
  messageBody.dateCapturedLocaleOffset =
    aMessageBodyMap.eventTimeStamp.getTimezoneOffset();

  let sessionObject = getUserSession();
  messageBody.sessionID = SessionID
    ? SessionID
    : sessionObject?.id
    ? sessionObject.id
    : EMPTY;

  // TODO: initialize session manager with SDK irrespective of the enableCapture setting
  // (i.e. when enableCapture = false, disables session manager, but API invocation needs session manager functionality)
  if (aMessageBodyMap?.assetLoadID) {
    messageBody.assetLoadID = aMessageBodyMap.assetLoadID;
  } else {
    let assetLoadSessionObject = getAssetLoadSession();
    // TODO: review if EMPTY should be an autogenerated id instead of EMPTY
    messageBody.assetLoadID = assetLoadSessionObject?.id
      ? assetLoadSessionObject.id
      : EMPTY;
  }

  Log.warn(
    'tm',
    messageBody.dateCapturedUTC,
    'sessionID:',
    messageBody.sessionID,
    'LoadID:',
    messageBody.assetLoadID,
    'asset:',
    messageBody.assetAddress,
  );

  HostedBy ? (messageBody.hostedBy = HostedBy) : EMPTY;
  Platform ? (messageBody.platform = Platform) : EMPTY;
  messageBody.eventSourceAppCode = ApplicationCode;
  messageBody.eventSourceAppVersion = ApplicationVersion;
  messageBody.activeTime = active;
  messageBody.idleTime = idle;
  messageBody.eventAttributes = eventAttributes;
  // TODO: add code to verify all required fields are valid (only if we are not in prod)
  let messageIsValid = validateMessageBody(messageBody, getConfig());

  if (messageIsValid) {
    aMessageBodyMap.eventValidated = 'SDK_YES';
  } else {
    aMessageBodyMap.eventValidated = 'SDK_NO';
    if (!isProd) {
      Log.critical(`invalid message blocked from submission`);
      return null;
    }
  }

  return messageBody;
};

export let filterAndSendJSEvents = (
  anEvent,
  aReportedValue,
  aMessageBodyMap = {},
  anEventAttributesMap = {},
) => {
  const startPerfMarker = startPerformanceMeasure(
    'filter-and-enqueue-jsevent',
    anEvent,
  );

  let opStatus = true;

  const userconfig = getConfig();

  aMessageBodyMap.interactionDescriptor = toTitleCase(
    aMessageBodyMap?.interactionDescriptor,
  );

  aMessageBodyMap.interactionCode = validateInteractionCode(
    aMessageBodyMap?.interactionCode,
  );

  let flattenedEventAttributes = flattenObject(anEventAttributesMap);

  opStatus = sendEventUnfiltered(
    anEvent,
    aReportedValue,
    aMessageBodyMap,
    flattenedEventAttributes,
  );
  endPerformanceMeasure(startPerfMarker);
  return opStatus;
};

// COMPONENTID //
export const sendEventUnfiltered = (
  anEvent,
  aCustomEventValue,
  aMessageBodyMap = {},
  anEventAttributesMap = {}, // args & custom data
) => {
  let opStatus = false;
  const messageQueue = getMessageQueue();

  if (window.dpanalyticsConf?.componentId > 1) {
    // setting it here
    // listen for changes (Proxy)
    anEventAttributesMap.componentId = window.dpanalyticsConf.componentId;
  }

  let actionevent = createEvent(
    anEvent,
    aCustomEventValue,
    aMessageBodyMap,
    anEventAttributesMap,
  );
  if (actionevent) {
    opStatus = messageQueue.Payload.Metrics.push(actionevent);
  }

  return opStatus;
};

let cachedHeader = null;

export const packageHeaderForTransmit = () => {
  if (!cachedHeader) {
    cachedHeader = {};
    cachedHeader.Header = {
      TxID: '',
      CaptureEnv: '',
      ResponseType: 'extended',
      DeviceInfo: {},
    };

    let {
      EndPoint,
      reportableEnvName,
      windowScreenWidth,
      windowScreenHeight,
      documentDisplayWidth,
      documentDisplayHeight,
      userLocaleLang,
      countryCode,
      regionCode,
      countryName,
      cityName,
      regionName,
      Username,
      additionalContext,
    } = getConfig();

    cachedHeader.Header.CaptureEnv = reportableEnvName;
    Username || isUnAuthenticatedRoute(EndPoint)
      ? (cachedHeader.Header.PublisherID = Username ? Username : ANONYMOUS_USER)
      : EMPTY;
    cachedHeader.Header.CaptureToolVersion = `js-sdk,${pkg.version}`;

    let parser = new UAParser();
    let browserInfo = parser.getBrowser();
    let osInfo = parser.getOS();
    let deviceInfo = parser.getDevice();

    cachedHeader.Header.DeviceInfo = {
      locale_lang: userLocaleLang,
      region_code: regionCode,
      country_name: countryName,
      country_code: countryCode,
      city_name: cityName,
      region_name: regionName,
      screen_resolution: `${windowScreenWidth}x${windowScreenHeight}`,
      document_size: `${documentDisplayWidth}x${documentDisplayHeight}`,
      // browser_name: browser.getBrowserName(),
      // browser_version: browser.getBrowserVersion(),
      // os_name: browser.getOSName(),
      // os_version: browser.getOSVersion(),
      // device_model: browser.getOSVersion(),
      // device_type: browser.getPlatformType(),
      // device_type?
      browser_name: browserInfo.name,
      browser_version: browserInfo.version,
      os_name: osInfo.name,
      os_version: osInfo.version,
      device_model: deviceInfo.model,
      user_agent: navigator.userAgent,
      info_num_cpus: window.navigator.hardwareConcurrency,
      info_memory_size: window.navigator.deviceMemory,
      info_screen_colors: window.screen.colorDepth,
      info_screen_pixel_ratio: window.devicePixelRatio,
      ...additionalContext,
    };

    if (window.performance?.memory) {
      cachedHeader.Header.DeviceInfo.browser_memory_js_heap_limit =
        window.performance.memory.jsHeapSizeLimit;
      cachedHeader.Header.DeviceInfo.browser_memory_js_heap_total =
        window.performance.memory.totalJSHeapSize;
      cachedHeader.Header.DeviceInfo.browser_memory_js_heap_used =
        window.performance.memory.usedJSHeapSize;
    }
  }
  return cachedHeader;
};

export const transmitImmediatelyOnBeacon = () => {
  transmitAllBufferedMessages(false);
};
export const transmitOnScheduler = () => {
  transmitAllBufferedMessages(true);
};

const transmitAllBufferedMessages = (transmitUsingScheduler) => {
  const startPerfMarker = startPerformanceMeasure('transmit-events');

  let transmissionStatus = false;
  let { EndPoint, isTransmitingToServer, isReact } = getConfig();

  if (!isTransmitingToServer) return transmissionStatus;

  let actionData = getMessageQueue();

  if (!actionData.Payload.Metrics || actionData.Payload.Metrics.length == 0) {
    return transmissionStatus;
  }

  const cachedHeader = packageHeaderForTransmit();
  cachedHeader.Header.TxID = uuid();
  cachedHeader.Header.DeviceInfo.query_params = encodeURIComponent(
    window.location?.search,
  );
  actionData.Header = cachedHeader.Header;

  // See note in nativeBehaviorEventHandlers:
  // "assetTitle not being reported in sendCapturedEvents, unavailable"
  // setting assetTitle to document.title here for SPAPageChanges
  if (isReact) {
    actionData.Payload.Metrics.forEach((message, index) => {
      if (message.interactionCode === 'SPAPageChange') {
        actionData.Payload.Metrics[index].assetTitle = document.title;
      }
    });
  }

  if (transmitUsingScheduler) {
    fetch(EndPoint, {
      headers: {
        'Content-Type': 'application/json',
        'X-HEADER-SDK': pkg.version,
      },
      method: 'POST',
      mode: 'cors',
      redirect: 'follow',
      keepalive: true,
      credentials: 'include',
      body: JSON.stringify(actionData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          const errorMessage = `Transmission failed to ${EndPoint}, response ${res.status}`;
          return Promise.reject(new Error(errorMessage));
        }
      })
      .then((response) => {
        if (response.Response.Header.Status !== 'SUCCESS') {
          const totalMessageCount = actionData.Payload.Metrics.length;
          const totalErrorCount = Object.keys(
            response.Response.Header.RejectedDetails,
          ).length;
          unsuccessfullResponse(
            totalMessageCount,
            totalErrorCount,
            response.Response.Header.Status,
            response.Response.Header.TxID,
          );
        } else {
          transmissionStatus = true;
        }
        actionData.Payload.Metrics = [];
      })
      .catch((error) => {
        const totalMessageCount = actionData.Payload.Metrics.length;
        Log.error('Post generated error:', error);
        if (error?.response) {
          // request made and server responded with a status
          // that falls outside of 2xx range
          Log.error('received response:', error.response);
        } else if (error?.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          Log.error('no response on request:', error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          Log.error('request composition error:', error?.message);
        }
        unsuccessfullResponse(
          totalMessageCount,
          totalMessageCount,
          'failed all',
          '0',
        );
        actionData.Payload.Metrics = [];
      });
  } else {
    transmissionStatus = sendBeaconRequest(EndPoint, actionData);
  }

  endPerformanceMeasure(startPerfMarker);

  return transmissionStatus;
};

const unsuccessfullResponse = (
  aTotalMessagesCount,
  aRejectedMessagesCount,
  aTransmissionStatus,
  aTxID,
) => {
  Log.error(
    `Rejected ${aRejectedMessagesCount} of ${aTotalMessagesCount} transmitted behavior events. Status: ${aTransmissionStatus}, Transaction ID: ${aTxID} `,
  );
};

export const sendBeaconRequest = (EndPoint, actionData) => {
  let header = { type: 'application/json' };
  let blob = new Blob([JSON.stringify(actionData)], header);
  let messageAccepted = false;
  try {
    messageAccepted = navigator.sendBeacon(EndPoint, blob);
  } catch (e) {
    messageAccepted = transmitAllBufferedMessages(true);
  }

  if (messageAccepted) {
    actionData.Payload.Metrics = [];
  }
  return messageAccepted;
};

setInterval(
  () => {
    let config = getConfig();

    if (
      config.isConfigValid &&
      config.isTransmitingToServer &&
      !config.AwaitUserName
    ) {
      transmitOnScheduler();
    }
  },
  sdkBehaviorConfig?.serverReportTimeoutMilis > 0
    ? sdkBehaviorConfig.serverReportTimeoutMilis
    : 10000,
);

export const setGlobalJourney = (endpoint) => {
  let isInternalEndpoint = isInternal(endpoint);
  let isProdEndpoint = isProd(endpoint);
  let request = new Request(endpoint, {
    headers: {
      'X-HEADER-SDK': pkg.version,
      'X-HEADER-DOMAIN': isInternalEndpoint ? 'jpmchase' : 'jpmorgan',
    },
    credentials: 'include',
  });

  fetch(request)
    .then((response) => response.text())
    .then((data) => {
      if (data && isInternalEndpoint) {
        let secondEndpoint = isProdEndpoint
          ? URL_JPMORGAN_PING_PROD
          : URL_JPMORGAN_PING_UAT;
        //set the X-Global cookie for jpmorgan domain
        fetch(
          new Request(secondEndpoint, {
            headers: {
              'X-HEADER-SDK': pkg.version,
              'X-HEADER-GLOBAL-VALUE': data,
            },
            credentials: 'include',
          }),
        ).catch((err) => console.error(`setGlobalJourney ${err}`));
      }
    })
    .catch((err) => console.error(`setGlobalJourney ${err}`));
};

import { filterAndSendJSEvents } from '../publish/sendCapturedEvents';
import { searchForOriginId } from './algorithms';
import { DPA_CUSTOM_EVENT_CAPTURE } from '../utils/constants';
import {
  toTitleCase,
  filterCustomData,
  extractClassName,
} from '../utils/functions';

const registryOfCustomEventHandlers = {};
const eventSelect = "[class*='dpa--']";
const eventClass = /^dpa--([a-z]+)--([\w]+[\w-]*)$/;

function eventHandlerForUserSpecifiedJSEvent(anEvent, customEventDescriptor) {
  const eventType = toTitleCase(anEvent.target.type);
  const eventOriginId = searchForOriginId({ event: anEvent });
  let messageBodyMap = {
    interactionCode: DPA_CUSTOM_EVENT_CAPTURE,
  };
  let customCapturedData = filterCustomData(anEvent.target.dataset);
  let eventAttributesMap = { origin_id: eventOriginId };
  filterAndSendJSEvents(anEvent, customEventDescriptor, messageBodyMap, {
    ...eventAttributesMap,
    ...customCapturedData,
  });
}

const addEvents = (node) => {
  const elements = node.querySelectorAll(eventSelect);
  elements
    ? Array.prototype.forEach.call(
        elements,
        filterEventsAndAddCustomEventListeners,
      )
    : null;
};

const filterEventsAndAddCustomEventListeners = (element) => {
  let extractedClassName = extractClassName(element);

  extractedClassName &&
    extractedClassName.split(' ').forEach((className) => {
      if (!eventClass.test(className)) return;
      const [, userSpecifiedJSEvent, userSpecifiedDescriptor] =
        className.split('--');
      if (!(className in registryOfCustomEventHandlers)) {
        registryOfCustomEventHandlers[className] =
          eventHandlerForUserSpecifiedJSEvent;
      }
      const registeredCustomEventHandler =
        registryOfCustomEventHandlers[className];
      element.addEventListener(
        userSpecifiedJSEvent,
        (event) => registeredCustomEventHandler(event, userSpecifiedDescriptor),
        true,
      );
    });
};

const monitorMutate = (mutations) => {
  mutations.forEach((mutation) => {
    const element = mutation.target;
    filterEventsAndAddCustomEventListeners(element);
    addEvents(element);
  });
};

export const initInstrumentedTracking = () => {
  if (document.readyState === 'complete') {
    addEvents(document);
    // trackView();
    const observer = new MutationObserver(monitorMutate);
    observer.observe(document, { childList: true, subtree: true });
  }
};

export const exportedForTesting = {
  eventHandlerForUserSpecifiedJSEvent,
  addEvents,
  filterEventsAndAddCustomEventListeners,
  monitorMutate,
};

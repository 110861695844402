import {
  searchForOriginId,
  searchForOriginTag,
  checkForAutoCapture,
} from '../algorithms';

import { input, textarea, select } from '../../utils/constants';
import {
  filterCustomData,
  removeEmptyArgs,
  sanitizeUserInput,
} from '../../utils/functions';
import { sendEventUnfiltered } from '../../publish/sendCapturedEvents';
import {
  NATIVELY_SUPPORTED_HTML_TAGS,
  DPA_SELECT_CHANGE,
  DPA_INPUT_CHANGE,
  DPA_TEXT_CHANGE,
} from '../../utils/constants';
import {
  startPerformanceMeasure,
  endPerformanceMeasure,
} from '../../utils/performance';

import Log from '../../utils/logger';

/**
 * function creates anEventInfo and calls processChangeEvents
 * @param {object} anEvent - a native DOM change event
 * NOTE: changeEventHandler DOES NOT RUN for synthetic uitk elements (SUEs)::
 * SUEs do NOT emit a change event (nativeBehaviorEventHandlers)
 * we detect SUEs with clicks and route them to processChangeEvents
 * we do not construct anEventInfo for SUEs
 */
export function changeEventHandler(anEvent) {
  const startPerfMarker = startPerformanceMeasure('change-event', anEvent);
  if (checkForAutoCapture(anEvent)) return;
  let eventInfo = {};
  let eventSourceTag = anEvent.target;
  eventInfo.tm_sinceload = Math.round(anEvent.timeStamp);
  eventInfo.origin_id = searchForOriginId({ event: anEvent });
  eventInfo.originalTagType = anEvent.target?.type;
  eventInfo.originHTMLTag = anEvent.target?.tagName?.toLowerCase();
  if (NATIVELY_SUPPORTED_HTML_TAGS.indexOf(eventInfo.originHTMLTag) === -1) {
    // return recognized eventSourceTag if found
    let foundActionableElement = searchForOriginTag(anEvent);
    if (foundActionableElement !== null) {
      eventSourceTag = foundActionableElement;
      if (foundActionableElement?.form) {
        foundActionableElement.form.id = sanitizeUserInput(
          foundActionableElement.form.id,
        );
      }
    }
  }
  eventInfo.eventTag = eventSourceTag;
  processChangeEvents(eventInfo);
  endPerformanceMeasure(startPerfMarker);
}

/**
 * anEventInfo param varies depending on whether
 * it is a native or synthetic uitk element (SUE)
 * @param {anEventInfo} anEventInfo
 */
export function processChangeEvents(anEventInfo) {
  let eventSourceTag;
  let HTMLTagName;
  let args = {};
  let messageBodyMap = {};

  // synthetic uitk components will NOT have an eventTag
  // we fork the processing here
  if (anEventInfo.eventTag) {
    eventSourceTag = anEventInfo.eventTag;
    HTMLTagName = eventSourceTag?.tagName?.toLowerCase();
    // formPostfix = anEventInfo.formPostfix;
  } else {
    eventSourceTag = false;
    HTMLTagName = anEventInfo.emulates?.toLowerCase();
  }

  switch (HTMLTagName) {
    case input: {
      let inputType = '';
      let originValue = '';
      if (
        eventSourceTag.type === 'radio' ||
        eventSourceTag.type === 'checkbox'
      ) {
        inputType = eventSourceTag?.type;
        originValue = eventSourceTag?.checked ? 'Selected' : 'Unselected';
      }
      if (eventSourceTag.type === 'text') {
        inputType = eventSourceTag?.type;
      }
      if (
        eventSourceTag?.list &&
        eventSourceTag.list.nodeName.toLowerCase() === 'datalist'
      ) {
        inputType = eventSourceTag?.list.nodeName.toLowerCase();
      }
      messageBodyMap.eventTimeStamp = new Date();
      let interaction_code = DPA_INPUT_CHANGE;

      args['event.execute.tm_since_load'] = anEventInfo.tm_sinceload;
      args['event.execute.origin_type'] = eventSourceTag.type;
      args['event.execute.origin_role'] = anEventInfo.originHTMLTag;
      args['event.execute.origin_id'] = anEventInfo.origin_id;
      args['event.execute.origin_value'] = originValue;
      args['event.execute.origin_label'] = (eventSourceTag)?eventSourceTag?.labels[0]?.innerText:null;

      if (eventSourceTag?.form) {
        args['event.execute.form_id'] = sanitizeUserInput(
          eventSourceTag.form?.id,
        );
      }
      args['event.execute.origin_role'] = anEventInfo.roleName
        ? anEventInfo.roleName
        : HTMLTagName;
      let customData = filterCustomData(eventSourceTag.dataset);
      args = removeEmptyArgs(args);

      messageBodyMap = {
        interactionCode: interaction_code,
      };
      sendEventUnfiltered(anEventInfo, 'Change event handler', messageBodyMap, {
        ...args,
        ...customData,
      });
      break;
    }

    case select: {
      // native HTML element
      if (eventSourceTag) {
        messageBodyMap.eventTimeStamp = new Date();
        let interaction_code = DPA_SELECT_CHANGE;
        let choicesSelected = 0;

        if (!eventSourceTag.multiple) {
          choicesSelected = 1;
        } else {
          let count = 0;
          for (let i = 0; i < eventSourceTag.options.length; ++i) {
            if (eventSourceTag.options[i].selected) {
              ++count;
            }
          }
          choicesSelected = count;
        }

        args.target_content = eventSourceTag.multiple;
        args['event.execute.origin_value'] =
          choicesSelected > 0 ? choicesSelected : '';
        args['event.execute.origin_id'] = anEventInfo.origin_id;
        args['event.execute.tm_since_load'] = anEventInfo.tm_sinceload;
        args['event.execute.origin_type'] = eventSourceTag.type;
        args['event.execute.origin_role'] = anEventInfo.originHTMLTag;
        if (eventSourceTag?.form) {
          args['event.execute.form_id'] = sanitizeUserInput(
            eventSourceTag.form?.id,
          );
        }
        let customData = filterCustomData(eventSourceTag.dataset);
        args = removeEmptyArgs(args);

        messageBodyMap = {
          interactionCode: interaction_code,
        };

        sendEventUnfiltered(
          anEventInfo,
          'Change event handler',
          messageBodyMap,
          {
            ...args,
            ...customData,
          },
        );
      }

      // no eventSourceTag: we have an SUE
      if (!eventSourceTag) {
        messageBodyMap.eventTimeStamp = new Date();
        let interaction_code = DPA_SELECT_CHANGE;

        // no support for multiple select SUEs
        // choicesSelected will always be one
        let choicesSelected = 1;
        let args = {};
        args['event.execute.origin_id'] = anEventInfo.id;
        args['event.execute.tm_since_load'] = anEventInfo.tm_sinceload;
        args['event.execute.target_text'] = sanitizeUserInput(anEventInfo.text);
        if (anEventInfo?.form) {
          args['event.execute.form_id'] = sanitizeUserInput(anEventInfo.form);
        }

        messageBodyMap = {
          eventTimeStamp: new Date(),
          // TODO: validate event
          eventValidated: 'SDK_NO',
          interactionCode: interaction_code,
        };
        let customData = filterCustomData(eventSourceTag.dataset);
        args = removeEmptyArgs(args);

        sendEventUnfiltered(
          anEventInfo,
          'Change event handler',
          messageBodyMap,
          {
            ...args,
            ...customData,
          },
        );
      }
      break;
    }

    case textarea: {
      messageBodyMap.eventTimeStamp = new Date();
      let interaction_code = DPA_TEXT_CHANGE;
      args.target_content = eventSourceTag.multiple;
      args['event.execute.origin_id'] = anEventInfo.origin_id;
      args['event.execute.origin_type'] = anEventInfo.originHTMLTag;
      args['event.execute.origin_role'] = anEventInfo.originHTMLTag;
      args['event.execute.tm_since_load'] = anEventInfo.tm_sinceload;

      let customData = filterCustomData(eventSourceTag.dataset);
      args = removeEmptyArgs(args);

      messageBodyMap = {
        interactionCode: interaction_code,
      };

      sendEventUnfiltered(anEventInfo, 'Change event handler', messageBodyMap, {
        ...args,
        ...customData,
      });
      break;
    }

    default: {
      Log.warn(`${HTMLTagName} not registered in changeEventHandler`);
      break;
    }
  }
}

// must be lower case
export const NATIVELY_SUPPORTED_HTML_TAGS = [
  'a',
  'button',
  'input',
  'textarea',
  'select',
  'radio',
  'checkbox',
  'text',
  'datalist',
];

export const DPA_INPUT_JS_FRAMEWORK = ['None', 'React'];

// DeploymentMode
export const DPA_INPUT_DEPLOYMENT_MODE = ['Internal', 'External'];

export const DPA_INPUT_CAPTURE_ENV = [
  'TEST',
  'DEV',
  'UAT',
  'QA',
  'OTHER',
  'PROD',
  'TEST-REPORT',
  'DEV-REPORT',
  'UAT-REPORT',
  'QA-REPORT',
  'OTHER-REPORT',
];

export const DPA_INPUT_AUTH_TYPE = ['Janus', 'IDA', 'None'];

export const DPA_INPUT_RESPONSE_TYPE = [
  'legacy',
  'minimal',
  'summary',
  'extended',
];

export const DPA_PAGE_LOAD = 'Page.Load';
export const DPA_PAGE_CHANGE = 'Page.Change';
export const DPA_PAGE_HIDE = 'Page.Hide';
export const DPA_PAGE_SHOW = 'Page.Show';
export const DPA_PAGE_DESTROY = 'Page.Destroy';
export const DPA_FRAME_LOAD = 'Frame.Load';
export const DPA_FRAME_HIDE = 'Frame.Hide';
export const DPA_FRAME_DESTROY = 'Frame.Destroy';
export const DPA_FRAME_SHOW = 'Frame.Show';
export const DPA_SPA_PAGE_CHANGE = 'SPAPageChange';
export const DPA_EVENT_CAPTURE = 'Event.Capture';
export const DPA_CUSTOM_EVENT_CAPTURE = 'CustomEventCapture';
export const DPA_CONTENT_CHANGE = 'Content.Change';
export const DPA_SELECT_CHANGE = 'SelectChange';
export const DPA_INPUT_CHANGE = 'Input.Change';
export const DPA_TEXT_CHANGE = 'TextChange';
export const DPA_LINK_CLICK = 'Link.Click';
export const DPA_BUTTON_CLICK = 'Button.Click';
export const DPA_RUNTIME_ERROR = 'Runtime.Error';
export const APPLICATION_LAUNCH = 'Application.Launch';
export const DPA_FORM_SUBMIT = 'Form.Submit';
export const DPA_FORM_RESET = 'Form.Reset';
// export const PAGE_CHANGE = 'pageChange';
export const PAGE_SHOW = 'pageshow';
export const PAGE_UNLOAD = 'unload';
export const PAGE_BEFORE_UNLOAD = 'beforeunload';
export const PAGE_VISIBILITY = 'visibilitychange';
export const PAGE_HIDE = 'pagehide';
export const PAGE_LOAD = 'load';
export const CLICK = 'click';
export const CHANGE = 'change';
export const SUBMIT = 'submit';
export const RESET = 'reset';
export const USER_INPUT = 'userinput';
export const KEY_UP = 'keyup';
export const ACTIVE_TIME = 'activeTime';
export const IDLE_TIME = 'idleTime';
export const HASH_SEPARATOR = '#';
export const ANONYMOUS_USER = 'ANONYMOUS';
export const [
  a,
  button,
  div,
  html,
  input,
  text,
  select,
  datalist,
  textarea,
  click,
  span,
  body,
  id,
  arialabel,
  title,
  alt,
  empty,
  synthetic_dropdown,
] = [
  'a',
  'button',
  'div',
  'html',
  'input',
  'text',
  'select',
  'datalist',
  'textarea',
  'click',
  'span',
  'body',
  'id',
  'aria-label',
  'title',
  'alt',
  'synthetic_dropdown',
  '',
];

export const EMPTY = '';
export const COLORS = {
  warning: '#d65513',
  error: '#e32b16',
  info: '#2670a9',
  debug: '03a062',
  success: '#24874b',
};
export const X_HEADER_SDK = 'X-HEADER-SDK';

export const PUBLIC_UNAUTHENTICATED_EXT = 'publicUnauthenticated';
export const UNAUTHENTICATED_EXT = 'ulogging';
export const URL_SEPARATOR = '/';
export const URL_CONTEXT = 'metricsservice';
export const URL_CONTEXT_SITEMINDER = 'dpanalytics';
export const URL_ADFS_REFRESH = '/api/adfs/refresh';
export const URL_PING_EXT = 'ping';
export const URL_JPMORGAN_PING_PROD =
  'https://markets.jpmorgan.com/metricsservice/v3/publicUnauthenticated/ping';
export const URL_JPMORGAN_PING_UAT =
  'https://markets-uat.jpmorgan.com/metricsservice/v3/publicUnauthenticated/ping';

const LOG_PREFIX = 'DPAnalytics::';

class Log {
  constructor(aLogLevel = Log.LEVEL_LOG_CURRENT) {
    this.logLevel = aLogLevel;
  }

  static LEVEL_DEBUG = 0;
  static LEVEL_WARN = 1;
  static LEVEL_ERROR = 2;
  static LEVEL_CRITICAL = 3;

  static LEVEL_LOG_CURRENT = Log.LEVEL_ERROR;

  static setLogLevel(aLogLevel = Log.LEVEL_ERROR) {
    Log.LEVEL_LOG_CURRENT = aLogLevel;
  }

  // using implied args, aParameterStub not required
  static debug(aParameterStub = {}) {
    if (Log.LEVEL_DEBUG >= Log.LEVEL_LOG_CURRENT) {
      console.log.apply(console, [...arguments]);
      // console.trace();
    }
  }

  static warn() {
    if (Log.LEVEL_WARN >= Log.LEVEL_LOG_CURRENT) {
      console.warn.apply(console, [...arguments]);
    }
  }

  static error() {
    if (Log.LEVEL_ERROR >= Log.LEVEL_LOG_CURRENT) {
      console.error.apply(console, [...arguments]);
    }
  }

  static critical() {
    if (Log.LEVEL_CRITICAL >= Log.LEVEL_LOG_CURRENT) {
      console.error.apply(console, [...arguments]);
    }
  }
}

export default Log;

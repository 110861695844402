!(function (n, t) {
  'object' == typeof exports && 'undefined' != typeof module
    ? t(exports)
    : 'function' == typeof define && define.amd
    ? define(['exports'], t)
    : t(((n || self).analyticsUtilActivity = {}));
})(this, function (n) {
  function t() {
    return (t =
      Object.assign ||
      function (n) {
        for (var t = 1; t < arguments.length; t++) {
          var e = arguments[t];
          for (var r in e)
            Object.prototype.hasOwnProperty.call(e, r) && (n[r] = e[r]);
        }
        return n;
      }).apply(this, arguments);
  }
  var e = [
    ['add', 'attach'],
    ['remove', 'detach'],
  ];
  function r(n) {
    var t = n ? e : e.reverse(),
      r = t[1],
      c = t[0][0] + 'EventListener';
    return function (t, e, f, a) {
      if ('undefined' == typeof window) return function () {};
      var d,
        l = a || !1,
        s =
          ((e = (o(e) ? e.split(' ') : e).map(function (n) {
            return n.trim();
          })),
          o(t) ? Array.from(document.querySelectorAll(t)) : [t]);
      if (!s.length) throw new Error('noElements');
      if (!e.length) throw new Error('noEvent');
      return s[0][c]
        ? ((d = i(function () {
            return s.map(function (n) {
              var t = u(f, l);
              return (
                e.forEach(function (e) {
                  return n[c](e, t, l);
                }),
                t
              );
            });
          })),
          function () {
            return (
              s.forEach(function (n, t) {
                return e.forEach(function (e) {
                  n[r[0] + 'EventListener'](e, d.listeners[t], l);
                });
              }),
              d.fn
            );
          })
        : ((d = i(function () {
            return s.map(function (t) {
              e.forEach(function (e) {
                return (t['on' + e] = n ? u(f, l) : null);
              });
            });
          })),
          function () {
            return (
              s.forEach(function (t) {
                e.forEach(function (e) {
                  return (t['on' + e] = n ? null : u(f, l));
                });
              }),
              d.fn
            );
          });
    };
  }
  function o(n) {
    return 'string' == typeof n;
  }
  function u(n, t) {
    return t && t.once
      ? ((e = n),
        function () {
          return e && ((r = e.apply(this, arguments)), (e = null)), r;
        })
      : n;
    var e, r;
  }
  function i(n) {
    var t = n();
    return { fn: n, listeners: t };
  }
  var c = r(!0);
  r();
  var f = 'undefined' == typeof window,
    a = 'hidden',
    d = [
      'mousemove',
      'mousedown',
      'touchmove',
      'touchstart',
      'touchend',
      'keydown',
    ];
  function l(n, t) {
    void 0 === t && (t = {});
    var e = (function (n, t) {
        var e = this,
          r = !1;
        return function (o) {
          r ||
            (n.call(e, o),
            (r = !0),
            setTimeout(function () {
              return (r = !1);
            }, t));
        };
      })(n, t.throttle || 1e4),
      r = [];
    function o() {
      var n = (function (n) {
        if (f) return !1;
        var t =
            f || a in document
              ? a
              : ['webkit', 'moz', 'ms', 'o'].reduce(function (n, t) {
                  var e = t + 'Hidden';
                  return !n && e in document ? e : n;
                }, null),
          e = ''.concat(t.replace(/[H|h]idden/, ''), 'visibilitychange'),
          r = function () {
            return n(Boolean(document[t]));
          },
          o = function () {
            return document.addEventListener(e, r);
          };
        return (
          o(),
          function () {
            return document.removeEventListener(e, r), o;
          }
        );
      })(function (n) {
        n || e({ type: 'tabVisible' });
      });
      return (
        (r = [n]
          .concat(
            d.map(function (n) {
              return c(document, n, e);
            }),
          )
          .concat(c(window, 'load', e))
          .concat(c(window, 'scroll', e, { capture: !0, passive: !0 }))),
        u
      );
    }
    function u() {
      r.map(function (n) {
        return n();
      });
    }
    return (
      o(),
      function () {
        return u(), o;
      }
    );
  }
  function s(n) {
    var t,
      e,
      r = n.onIdle,
      o = n.onWakeUp,
      u = n.onHeartbeat,
      i = n.timeout,
      c = void 0 === i ? 1e4 : i,
      f = n.throttle,
      a = void 0 === f ? 2e3 : f,
      d = !1,
      s = !1,
      p = new Date(),
      h = function () {
        return clearTimeout(t);
      };
    function m(n) {
      h(),
        u && !d && u(v(p), n),
        o && d && ((d = !1), o(v(e), n), (p = new Date())),
        (t = setTimeout(function () {
          (d = !0), r && ((e = new Date()), r(v(p), n));
        }, c));
    }
    var w = l(m, { throttle: a });
    return {
      disable: function () {
        (s = !0), (d = !1), h();
        var n = w();
        return function () {
          return (s = !1), (p = new Date()), m({ type: 'load' }), n();
        };
      },
      getStatus: function () {
        return {
          isIdle: d,
          isDisabled: s,
          active: d ? 0 : v(p, s),
          idle: d ? v(e, s) : 0,
        };
      },
    };
  }
  function v(n, t) {
    return t ? 0 : Math.round((new Date() - n) / 1e3);
  }
  (n.onDomActivity = l),
    (n.onIdle = function (n, e) {
      return void 0 === e && (e = {}), s(t({ onIdle: n }, e));
    }),
    (n.onUserActivity = s),
    (n.onWakeup = function (n, e) {
      return void 0 === e && (e = {}), s(t({ onWakeUp: n }, e));
    });
});

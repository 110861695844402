import { getConfig } from '../config/sdkConfig';

export function startPerformanceMeasure(
  aPerfTestName,
  aBrowserEvent = null,
  anApplicationId = null,
  isTest = false
) {
  const sdkConfig = getConfig();
  if (sdkConfig.doPerformanceMeasure || isTest) {
    const fullTestName = `dpa-${aPerfTestName}`;
    const perfMetadata = {
      app_id: anApplicationId ? anApplicationId : sdkConfig.ApplicationCode, // add from SDK config
      event_time: new Date().toISOString(),
    };

    if (aBrowserEvent || isTest) {
      perfMetadata.event_time_stamp = aBrowserEvent.timeStamp;
      perfMetadata.event_type = aBrowserEvent.type;
      perfMetadata.event_input_type = aBrowserEvent.pointerType;
      perfMetadata.event_source_uri = aBrowserEvent.target?.baseURI;
      perfMetadata.event_source_tag = aBrowserEvent.target?.nodeName;
      perfMetadata.event_source_parent_tag =
        aBrowserEvent.target?.offsetParent?.nodeName;
      perfMetadata.event_source_id =
        aBrowserEvent.target?.id || aBrowserEvent.target?.dataset?.testId;
    }

    const startPerfMarker = performance?.mark(fullTestName, {
      detail: perfMetadata,
    });
    return startPerfMarker;
  } else return null;
}

export function endPerformanceMeasure(aStartPerfMarker) {
  if (!aStartPerfMarker) {
    return null;
  }
  const perfTestName = aStartPerfMarker.name;
  // Performance mark end of the function
  const endPerMarker = performance.mark(perfTestName, {
    detail: aStartPerfMarker.detail,
  });

  // Computes system delay between user event and start of execution of this handler
  const eventDurationBeforeHandler = performance.measure(
    perfTestName + '-before',
    {
      start: aStartPerfMarker.detail.event_time_stamp,
      end: aStartPerfMarker.startTime,
      detail: aStartPerfMarker.detail,
    },
  );

  console.groupCollapsed(`SDK perf measure ${perfTestName}`);
  console.log(
    eventDurationBeforeHandler.name,
    eventDurationBeforeHandler.duration,
  );

  // Compute duration of the execution
  const eventDurationInHandler = performance.measure(
    perfTestName + '-duration',
    {
      start: aStartPerfMarker.startTime,
      end: endPerMarker.startTime,
      detail: aStartPerfMarker.detail,
    },
  );

  console.log(eventDurationInHandler.name, eventDurationInHandler.duration);

  console.groupEnd();
  return eventDurationInHandler;
}

import { getMessageQueue } from '../data/messageQueue';
import Log from '../utils/logger';
import { generateEndPointURL } from '../analytics/algorithms/endpoint';
import { transmitImmediatelyOnBeacon } from '../publish/sendCapturedEvents';

const SDK_CONFIG_DEFAULTS = {
  isConfigValid: false,
  isCaptureEnabled: false,
  ResponseType: 'Extended',
};
let sdkConfig = {};
let sessionManager = {};
let activityPair = { idle: 0, active: 0 };

export let sdkBehaviorConfig = {
  serverReportTimeoutMilis: 10 * 1000,
  sessionTimeoutMinutes: 60,
  userActivityOnPageTimeoutMilis: 5 * 1000,
  userActivityOnPageTimeoutThrottleMilis: 3 * 1000,
  isaNewApplicationLaunch: false,
  isaNewApplicationLaunchReact: true,
};

function setApplicationPlacementLevel() {
  if (window.self !== window.top) {
    return 'Subordinate';
  }
  return 'Top';
}

// TODO: break up into smaller functions by function
let copyValidatedUserConfig = (userRequestedConfig, additionalContext) => {
  sdkConfig = {
    ...SDK_CONFIG_DEFAULTS,
    ...{
      // Header fields
      CaptureEnv: userRequestedConfig.CaptureEnv,
      ResponseType: userRequestedConfig.ResponseType,
      EndPoint: userRequestedConfig.EndPoint,
      // Body fields
      Platform: userRequestedConfig.Platform,
      HostedBy: userRequestedConfig?.HostedBy,
      ApplicationCode: userRequestedConfig.ApplicationCode,
      ApplicationVersion: userRequestedConfig.ApplicationVersion,
      ApplicationComponentCode: userRequestedConfig?.ApplicationComponentCode,
      ApplicationPlacementLevel: setApplicationPlacementLevel(),
      Username: userRequestedConfig.Username?.toUpperCase(),
      SessionID: userRequestedConfig.SessionID,
      // Dimensions
      windowScreenWidth: window.screen.width,
      windowScreenHeight: window.screen.height,
      windowScreenPixelDepth: window.screen.pixelDepth,
      documentDisplayWidth: window.innerWidth,
      documentDisplayHeight: window.innerHeight,
      // AwaitUserName
      AwaitUserName: userRequestedConfig.AwaitUserName ? true : false,
      idaRefreshInterval: userRequestedConfig?.idaRefreshInterval
        ? userRequestedConfig?.idaRefreshInterval
        : 900000,
    },
    ...{
      isProd: userRequestedConfig.CaptureEnv === 'PROD',
      isQA:
        userRequestedConfig.CaptureEnv === 'QA' ||
        userRequestedConfig.CaptureEnv === 'QA-REPORT',
      isDev:
        userRequestedConfig.CaptureEnv === 'DEV' ||
        userRequestedConfig.CaptureEnv === 'DEV-REPORT',
      isUAT:
        userRequestedConfig.CaptureEnv === 'UAT' ||
        userRequestedConfig.CaptureEnv === 'UAT-REPORT',
      isTEST:
        userRequestedConfig.CaptureEnv === 'TEST' ||
        userRequestedConfig.CaptureEnv === 'TEST-REPORT',
    },
    ...{
      // switch per customer
      originByIdNameClass: true,
    },
    isReact: userRequestedConfig.jsFramework.toLowerCase() === 'react',
    isNoFramework: userRequestedConfig.jsFramework.toLowerCase() === 'none',
    isVue: userRequestedConfig.jsFramework.toLowerCase() === 'vue',
    isAngular: userRequestedConfig.jsFramework.toLowerCase() === 'angular',
    // TEMPORARY
    isIda: userRequestedConfig.EndPoint?.includes('ilogging') ? true : false,
    // mutation
    isListeningForMutations: userRequestedConfig.EnableNonDefaultEventListeners
      ? true
      : false,
  };

  // set debug level
  let debugLevel = sdkConfig.isProd
    ? Log.LEVEL_CRITICAL
    : sdkConfig.isDev
    ? Log.LEVEL_DEBUG
    : Log.LEVEL_ERROR;
  if (userRequestedConfig.DEBUG_LEVEL >= 0) {
    if (
      Number.isInteger(userRequestedConfig.DEBUG_LEVEL) &&
      userRequestedConfig.DEBUG_LEVEL <= 3 &&
      userRequestedConfig.DEBUG_LEVEL >= 0
    ) {
      debugLevel = userRequestedConfig.DEBUG_LEVEL;
    } else {
      Log.critical(
        `Invalid debug level: ${userRequestedConfig.DEBUG_LEVEL} (must be 0 through 3)`,
      );
    }
  }

  Log.setLogLevel(debugLevel);

  // HERE
  // SDK user metrics failed to initialize with error=TypeError: undefined is not an object (evaluating 'o[1].toUpperCase')
  try {
    let foundLocaleInfo = Intl.DateTimeFormat()
      .resolvedOptions()
      .locale.split('-'); // eg: ['en', 'US']

    sdkConfig.userLocaleLang = foundLocaleInfo[0].toLowerCase();
    sdkConfig.countryCode = foundLocaleInfo[1].toUpperCase();

    let userTimeZone = Intl.DateTimeFormat()
      .resolvedOptions()
      .timeZone.split('/'); // eg ['America', 'New_York']

    sdkConfig.regionName = userTimeZone[0];
    sdkConfig.cityName = userTimeZone[1];
  } catch (error) {
    console.error(error);
  }

  sdkConfig.isTransmitingToServer = false;
  sdkConfig.reportableEnvName = 'NONE';
  if (userRequestedConfig.CaptureEnv === 'PROD') {
    sdkConfig.isTransmitingToServer = true;
    sdkConfig.reportableEnvName = userRequestedConfig.CaptureEnv;
  }

  if (userRequestedConfig.CaptureEnv.includes('-REPORT')) {
    sdkConfig.isTransmitingToServer = true;
    sdkConfig.reportableEnvName = userRequestedConfig.CaptureEnv.slice(0, -7);
  }

  if (!sdkConfig.EndPoint) {
    sdkConfig.EndPoint = generateEndPointURL(
      sdkConfig.reportableEnvName,
      userRequestedConfig.DeploymentMode,
      userRequestedConfig.AuthType,
      userRequestedConfig.Platform,
    );
  }

  sdkConfig.enableCapture =
    userRequestedConfig.enableCapture === false ? false : true;
  if (sdkConfig.enableCapture === false) {
    Log.debug(
      `all SDK-based capture has been disabled (API capture is enabled)`,
    );
    sdkConfig.isCaptureEnabled = false;
    sdkConfig.isConfigValid = true;
  } else {
    sdkConfig.isCaptureEnabled = true;
    sdkConfig.isConfigValid = true;
  }

  sdkConfig.enableAutoCapture = false;
  if (userRequestedConfig.enableAutoCapture === true) {
    if (sdkConfig.enableCapture === true) {
      sdkConfig.enableAutoCapture = true;
      sdkConfig.isCaptureEnabled = true;
      sdkConfig.isConfigValid = true;
    } else {
      sdkConfig.isCaptureEnabled = false;
      sdkConfig.isConfigValid = false;
      Log.warn(
        `invalid configuration: Capture is required to enable AutoCapture`,
      );
    }
  }

  sdkConfig.enableClickStream = false;
  if (userRequestedConfig.enableClickStream === true) {
    if (
      sdkConfig.enableAutoCapture === true &&
      sdkConfig.enableCapture === true
    ) {
      sdkConfig.enableClickStream = true;
      sdkConfig.isCaptureEnabled = true;
      sdkConfig.isConfigValid = true;
    } else {
      sdkConfig.isCaptureEnabled = false;
      sdkConfig.isConfigValid = false;
      Log.warn(
        `invalid configuration: Capture and AutoCapture are required to enable ClickStream`,
      );
    }
  }

  // to add advanced options to sdkConfig
  sdkConfig.doPerformanceMeasure =
    userRequestedConfig.ReportSDKPerformance == true;

  if (additionalContext && Object.entries(additionalContext).length) {
    sdkConfig = { ...sdkConfig, additionalContext: { ...additionalContext } };
  }

  return sdkConfig;
};

const getConfig = () => sdkConfig;

const setActivetime = (active) => {
  let appActivityPair = getActivityTime();
  appActivityPair.active += active;
  setActivity(appActivityPair);
};

const setIdletime = (idle) => {
  let appActivityPair = getActivityTime();
  appActivityPair.idle += idle;
  setActivity(appActivityPair);
};

export const resetActivityCounters = () => {
  activityPair.idle = 0;
  activityPair.active = 0;
};

// 1. user
const setUserSession = (aUserSession = {}) => {
  sessionManager.userSession = { ...aUserSession };
};
// 2. tab
const setTabSession = (aTabSession = {}) => {
  sessionManager.tabSession = { ...aTabSession };
};
// 2. asset load
const setAssetLoadSession = (aAssetLoadSession = {}) => {
  sessionManager.assetLoadSession = { ...aAssetLoadSession };
};

const setActivity = (anActivityPair) => {
  activityPair.active = anActivityPair?.active;
  activityPair.idle = anActivityPair?.idle;
};

function getActivityTime() {
  return activityPair;
}

const getUserSession = () => sessionManager?.userSession;
const getTabSession = () => sessionManager?.tabSession;
const getAssetLoadSession = () => sessionManager?.assetLoadSession;

const injectUsername = (username) => {
  sdkConfig.Username = username;
  sdkConfig.AwaitUserName = false;
  transmitImmediatelyOnBeacon();
};

export {
  copyValidatedUserConfig,
  getConfig,
  setActivetime,
  setIdletime,
  setActivity,
  getActivityTime,
  // getDPAnalytics,
  // setDPAnalytics,
  setUserSession,
  setTabSession,
  setAssetLoadSession,
  getUserSession,
  getTabSession,
  getAssetLoadSession,
  injectUsername,
};

import {
  searchForOriginId,
  // composeFormDescription,
  checkForAutoCapture,
} from '../algorithms';

// import { toTitleCase } from '../../utils/functions';
import {
  filterCustomData,
  removeEmptyArgs,
  sanitizeUserInput,
} from '../../utils/functions';
import { sendEventUnfiltered } from '../../publish/sendCapturedEvents';
import { DPA_FORM_SUBMIT } from '../../utils/constants';

export const submitEventHandler = (aSubmitEvent) => {
  if (checkForAutoCapture(aSubmitEvent)) return;
  let args = {};
  let messageBodyMap = {};
  // let customPostfix = ' - Validated form submit';
  // let formPostfix = composeFormDescription(aSubmitEvent.target);
  let interaction_code = DPA_FORM_SUBMIT;
  // let interaction_descriptor = `${toTitleCase(
  //   aSubmitEvent.type,
  // )}${customPostfix}${formPostfix}`;
  args['event.execute.tm_since_load'] = Math.round(aSubmitEvent.timeStamp);
  messageBodyMap.eventTimeStamp = new Date();
  args['event.execute.origin_type'] = aSubmitEvent.type;
  args['event.execute.origin_id'] = searchForOriginId({ event: aSubmitEvent });
  if (aSubmitEvent?.form) {
    args['event.execute.form_id'] = sanitizeUserInput(aSubmitEvent.form?.id);
  }
  let custom_data = filterCustomData(aSubmitEvent.target.dataset);
  args = removeEmptyArgs(args);

  messageBodyMap = {
    interactionCode: interaction_code,
    // interactionDescriptor: interaction_descriptor,
  };

  sendEventUnfiltered(aSubmitEvent, 'Submit event handler', messageBodyMap, {
    ...args,
    ...custom_data,
  });
};

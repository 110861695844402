import { copyValidatedUserConfig, injectUsername } from '../config/sdkConfig';
import initializeAnalytics from './initRuntime';

import pkg from '../../package.json';

import { validateUserInputs } from '../utils/validation';
import Log from '../utils/logger';
import * as packageJson from '../../package.json';

/**
 * @param {object} aUserRequestedConfig
 * @returns {boolean} - init status
 */
export const initialize = (aUserRequestedConfig, additionalContext = {}) => {
  try {
    if (window.top.Container?.getInterfaceVersion() == '1') {
      let analyticsSDKOptions = {};

      if (top.Container.getContainerExecutionEnvironment)
        analyticsSDKOptions = {
          ...analyticsSDKOptions,
          ...{ CaptureEnv: top.Container.getContainerExecutionEnvironment() },
        };

      if (top.Container.getContainerLabelCode)
        analyticsSDKOptions = {
          ...analyticsSDKOptions,
          ...{ Platform: top.Container.getContainerPlatformCode() },
        };

      if (top.Container.getContainerEndPoint)
        analyticsSDKOptions = {
          ...analyticsSDKOptions,
          ...{ EndPoint: top.Container.getContainerEndPoint() },
        };

      if (top.Container.getCurrentContainerSessionID)
        analyticsSDKOptions = {
          ...analyticsSDKOptions,
          ...{ SessionID: top.Container.getCurrentContainerSessionID() },
        };

      if (top.Container.getCurrentApplicationID)
        analyticsSDKOptions = {
          ...analyticsSDKOptions,
          ...{ ApplicationCode: top.Container.getCurrentApplicationID() },
        };

      if (top.Container.getCurrentApplicationVersion)
        analyticsSDKOptions = {
          ...analyticsSDKOptions,
          ...{
            ApplicationVersion: top.Container.getCurrentApplicationVersion(),
          },
        };

      aUserRequestedConfig = {
        ...analyticsSDKOptions,
        ...aUserRequestedConfig,
      };
    }
    try {
      //Take only the first 10 entries of the additional context provided.
      let restrictedAdditionalContext = {};
      let keys = Object.keys(additionalContext);
      keys.forEach((key) => {
        let obj = JSON.parse(`{"${key}":"${additionalContext[key]}"}`);
        if (
          Object.entries(restrictedAdditionalContext).length < 10 &&
          key.length < 100 &&
          (additionalContext[key] + '')?.length < 100
        )
          restrictedAdditionalContext = {
            ...restrictedAdditionalContext,
            ...obj,
          };
      });
      additionalContext = restrictedAdditionalContext;
    } catch (e) {
      console.error('error in processing additional context', e);
      additionalContext = {};
    }

    let initStatus = false;
    aUserRequestedConfig.AwaitUserName =
      aUserRequestedConfig?.AwaitUserName || false;

    const userInputsAreValid = validateUserInputs(aUserRequestedConfig);

    const isDpAnalyticsRunning = window.dpAnalyticsRunning || false;
    if (isDpAnalyticsRunning === true) {
      Log.warn(`duplicate invocation of dpAnalytics in current scope`);
      return initStatus;
    }

    if (userInputsAreValid) {
      const config = copyValidatedUserConfig(
        aUserRequestedConfig,
        additionalContext,
      );
      Log.debug(
        `✅ Analytics v${packageJson.version} config detected:
      ApplicationCode: ${aUserRequestedConfig.ApplicationCode},
      ApplicationVersion: ${aUserRequestedConfig.ApplicationVersion},
      CaptureEnv: ${aUserRequestedConfig.CaptureEnv},
      enableAutoCapture: ${
        aUserRequestedConfig.enableAutoCapture == false ? 'false' : 'true'
      },
      EndPoint: ${aUserRequestedConfig.EndPoint}, 
      Platform: ${aUserRequestedConfig.Platform},
      ${
        aUserRequestedConfig.PublisherID
          ? `PublisherID: ${aUserRequestedConfig.PublisherID}`
          : ``
      }
      Suppress logging in dev - add 'DEBUG_LEVEL: 3' to initialization.,
      additionalContext: ${
        config.additionalContext
          ? JSON.stringify(config.additionalContext)
          : 'no additionalContext provided'
      }`,
      );
      initializeAnalytics(config);
      window.dpAnalyticsRunning = true;
      initStatus = true;
      return initStatus;
    } else {
      Log.critical('Missing or invalid SDK configuration.');
      return initStatus;
    }
  } catch (error) {
    console.error(`dpAnalytics exiting: ${error}`);
  }
};

export const setUsername = (username) => {
  injectUsername(username);
};

export const getSDKWebAddress = (sdkVersion = '') => {
  if (!sdkVersion) {
    sdkVersion = pkg.version;
  }
  const webAddress = `https://athena-app.jpmchase.net/CORE/unpkg/@dpanalytics/dpAnalytics@${sdkVersion}/dist/dpAnalytics.js`;
  return webAddress;
};

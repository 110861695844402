import inBrowser from './inBrowser';
import noOp from './noOp';

export default function onRouteChange(callback = noOp) {
  if (!inBrowser) return;
  const { addEventListener, history, location } = window;
  // Observe native navigation
  addEventListener('popstate', () => {
    let attributesMap = {};
    attributesMap.asset_query = location.search;
    attributesMap.asset_hash = location.hash;
    callback(location.pathname, attributesMap);
  });

  // Observe manual navigation
  const methods = ['push', 'replace'];
  methods.map((type) => {
    const state = `${type}State`;
    // installs route change into history stack
    const historyState = history[state];
    return (history[state] = function () {
      let attributesMap = {};
      attributesMap.asset_query = location.search;
      attributesMap.asset_hash = location.hash;

      callback(arguments[2], attributesMap);

      // callback(targetURL);
      // callback(`${location.origin}${arguments[2]}`);
      return historyState.apply(history, arguments);
    });
  });
}

const createValidURL = (aLocationObject, aPathname) => {
  // location object gets updated at any time- do not use pathname inside of it
  let finalURL = '';
  let { origin, hash, search } = aLocationObject;
  if (!aPathname) {
    aPathname = '/';
  }
  finalURL = `${origin}${aPathname}${hash}${search}`;
  return finalURL;
};

import { getConfig } from '../../config/sdkConfig';
import { NATIVELY_SUPPORTED_HTML_TAGS } from '../../utils/constants';
import { sanitizeUserInput, sanitizeOriginID } from '../../utils/functions';
import Log from '../../utils/logger';
import {
  startPerformanceMeasure,
  endPerformanceMeasure,
} from '../../utils/performance';

/**
 * called by searchForOriginId, prioritizes id
 * @param {Object} evtTarget
 * @param {string} evtTarget.id
 * @param {string} evtTarget.name
 * @param {string} evtTarget.className
 * @returns {string} - a truncated or empty string
 */
export function setOriginByIdNameClass(evtTarget) {
  /** @type {string} */
  let foundId = '';
  if (evtTarget.id) {
    foundId = evtTarget.id.slice(0, 24);
    foundId = sanitizeOriginID(foundId);
    return foundId;
  } else if (evtTarget.dataset?.testid) {
    foundId = evtTarget.dataset.testid.slice(0, 24);
    foundId = sanitizeOriginID(foundId);
    return foundId;
  } else if (evtTarget.name) {
    foundId = evtTarget.name.slice(0, 24);
  } else if (typeof evtTarget.className === 'string') {
    foundId = evtTarget.className?.slice(0, 24);
  }
  if (foundId) {
    foundId = sanitizeUserInput(foundId);
  }
  return foundId;
}

/**
 * called by searchForOriginId
 * @param {event} evtTarget
 * @returns {string | undefined} - a truncated string or undef
 */
export const setOriginByClassTitle = (evtTarget) => {
  if (evtTarget.className) return evtTarget.className?.slice(0, 24);
  else if (evtTarget.title) return evtTarget.title.slice(0, 24);
  else return;
};

/**
 * searches up the dom for id
 * @param {event} searchStartParam
 * @returns {string} - a concatinated string
 */
export function searchForOriginId(searchStartParam = {}) {
  const startPerfMarker = startPerformanceMeasure('originId-search');
  let anEvent = null;
  let syntheticEventPath = null;
  if (searchStartParam.event) {
    anEvent = searchStartParam.event;
    syntheticEventPath = anEvent?.composedPath && anEvent.composedPath();
  } else if (searchStartParam.domElement) {
    syntheticEventPath = searchStartParam.domElement;
  }

  let userConfig = getConfig();
  let searchIdAlgo = userConfig.originByIdNameClass
    ? setOriginByIdNameClass
    : setOriginByClassTitle;

  let composedId = '';
  let prefix = '';
  for (let [i, el] of syntheticEventPath.entries()) {
    if (el.nodeName == 'BODY' || i > 5) {
      composedId = 'unknown';
      break;
    }
    let myOrigin = searchIdAlgo(el);
    if (myOrigin) {
      composedId = `${prefix}${myOrigin}`;
      break;
    }
    prefix += '#';
  }
  endPerformanceMeasure(startPerfMarker);
  return composedId;
}

/**
 * searchForOriginTag used in clickEventHandler and changeEventHandler
 * @param {object} anEvent
 * @returns {null | HTMLElement}
 */
export function searchForOriginTag(anEvent) {
  let syntheticEventPath = anEvent?.composedPath && anEvent.composedPath();
  for (let [i, el] of syntheticEventPath.entries()) {
    if (typeof el.nodeName === 'undefined' || el.nodeName === 'BODY' || i > 5) {
      return null;
    }
    if (
      NATIVELY_SUPPORTED_HTML_TAGS.indexOf(el.nodeName.toLowerCase()) !== -1
    ) {
      return el;
    }
  }
}

/**
 * called in clickEventHandler when no native element is found, checks for roles
 * @param {object} anEvent
 * @returns {object}
 */
export function searchForClosestSyntheticTag(anEvent) {
  let foundTag = {};
  let nearestRoleElement = anEvent.target?.closest('[role]')
    ? anEvent.target.closest('[role]')
    : null;
  if (nearestRoleElement !== null) {
    let parentRoleElement = null;
    // if the parentNode does not have a role search for closest
    if (nearestRoleElement.parentNode !== null) {
      parentRoleElement = nearestRoleElement?.parentNode?.closest('[role]');
    }
    const nativeTag = mapRolesToNativeElements(nearestRoleElement.role);
    // if no native tag is found
    foundTag.emulates = nativeTag;

    foundTag.role = nearestRoleElement.role;
    if (parentRoleElement && parentRoleElement.role) {
      foundTag.parentRole = parentRoleElement.role;
      foundTag.ORIGINAL_ID = parentRoleElement.id;
    } else {
      foundTag.parentRole = null;
    }
    foundTag.syntheticTag = nearestRoleElement;
    foundTag.parentTag = parentRoleElement;
    foundTag.isExpanded = parentRoleElement?.getAttribute('aria-expanded');
  }
  return foundTag;
}

/**
 * called by clickEventHandler to populate eventInfo.eventTagText
 * @param {event} anEvent
 * @returns {string} - the text node
 */
export function searchForClosestTextNode(anEvent) {
  let originalTag = anEvent.target;
  let foundTextNode = null;
  if (anEvent.target.hasChildNodes()) {
    let children = originalTag.childNodes;
    for (const node of children) {
      if (node.textContent) {
        foundTextNode = node.textContent;
        break; // break on first text node
      }
    }
  }
  return foundTextNode;
}

/**
 * called by searchForClosestSyntheticTag
 * @param {string} aSyntheticTag
 * @returns {string} - the string replacement
 */
function mapRolesToNativeElements(aSyntheticTag) {
  switch (aSyntheticTag) {
    case 'listbox': {
      return 'select';
    }
    case 'option': {
      return 'select';
    }
    case 'select': {
      return 'select';
    }
    case 'tab': {
      return 'a';
    }
    case 'treeitem': {
      return 'a';
    }
    case 'menuitem': {
      return 'a';
    }
    case 'button': {
      return 'button';
    }
    default: {
      break;
    }
  }
}

/**
 * called by clickEventHandler
 * @param {object} aFoundSyntheticTag
 * @returns {object} - a hydrated synthetic tag
 */
export function constructReplacementForNativeElement(aFoundSyntheticTag) {
  const emulatedTag = aFoundSyntheticTag.emulates;
  const replacementElement = {};
  replacementElement.id = aFoundSyntheticTag.syntheticTag?.id;
  replacementElement.title = aFoundSyntheticTag.syntheticTag?.title;
  replacementElement.class = aFoundSyntheticTag.syntheticTag?.className;
  replacementElement.form = aFoundSyntheticTag.syntheticTag?.form;
  replacementElement.eventAttributes = {};
  replacementElement.emulates = emulatedTag;

  switch (emulatedTag) {
    case 'select': {
      replacementElement.tagName = 'select';
      replacementElement.type = 'select';
      replacementElement.multiple = false;
      replacementElement.text = aFoundSyntheticTag.syntheticTag?.innerText;
      replacementElement.dataset = aFoundSyntheticTag.syntheticTag?.dataset;
      break;
    }
    case 'a': {
      replacementElement.tagName = 'a';
      replacementElement.type = 'anchor';
      break;
    }
    case 'button': {
      replacementElement.tagName = 'button';
      replacementElement.type = 'button';
      break;
    }
    default: {
      Log.debug(`unsupported emulated tag:: ${emulatedTag}`);
      break;
    }
  }
  return replacementElement;
}

/**
 * composes a string with id of form
 * @param {object} aForm
 * @returns {string} - id of the form
 */
export function composeFormDescription(aForm = null) {
  let formPostfix = '';
  if (aForm) {
    let formID = aForm?.id ? aForm.id : 'unspecified';
    formPostfix = ` - Form ${formID}`;
  }
  return formPostfix;
}

/**
 * checks for data-dpa-noautocapture data attribute
 * used in nativeBehaviorEventHandlers
 * @param {event} anEvent
 * @returns {boolean}
 */
export function checkForAutoCapture(anEvent) {
  const aComposedPath = anEvent.composedPath();
  for (let [i, el] of aComposedPath.entries()) {
    const anEventDataSet = aComposedPath[i].dataset;
    if (el.nodeName === 'BODY' || i > 4) {
      return false;
    }
    if (anEventDataSet && 'dpaNoautocapture' in anEventDataSet) {
      return true;
    }
  }
  return false;
}

export const exportedForTesting = {
  mapRolesToNativeElements,
};

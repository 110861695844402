import {
  DPA_INPUT_CAPTURE_ENV,
  DPA_INPUT_RESPONSE_TYPE,
  DPA_INPUT_DEPLOYMENT_MODE,
  DPA_INPUT_JS_FRAMEWORK,
  DPA_INPUT_AUTH_TYPE,
} from '../utils/constants';

import Log from './logger';

const validateRequiredInputField = (
  aFieldName,
  anInputField = null,
  aValidSetOfValues = [],
) => {
  let isValidInputField = false;
  if (anInputField === null || anInputField === undefined) {
    Log.error(`the required field [${aFieldName}] must be specified`);
    return isValidInputField;
  }
  if (anInputField === '') {
    Log.error(`the required field [${aFieldName}] must not be empty`);
    return isValidInputField;
  }

  if (
    aValidSetOfValues.length &&
    aValidSetOfValues.indexOf(anInputField) === -1
  ) {
    Log.error(
      `the required field [${aFieldName}] must have a valid value - one of ${aValidSetOfValues}`,
    );
    return isValidInputField;
  }
  isValidInputField = true;

  return isValidInputField;
};

export const validateUserInputs = (aUserRequestedConfig = {}) => {
  let userConfigIsValid = true;

  userConfigIsValid =
    userConfigIsValid &&
    validateRequiredInputField(
      'ApplicationCode',
      aUserRequestedConfig.ApplicationCode,
    );
  userConfigIsValid =
    userConfigIsValid &&
    validateRequiredInputField(
      'ApplicationVersion',
      aUserRequestedConfig.ApplicationVersion,
    );

  if (!aUserRequestedConfig.CaptureEnv) {
    aUserRequestedConfig.CaptureEnv = DPA_INPUT_CAPTURE_ENV[5];
  }
  userConfigIsValid =
    userConfigIsValid &&
    validateRequiredInputField(
      'CaptureEnv',
      aUserRequestedConfig.CaptureEnv,
      DPA_INPUT_CAPTURE_ENV,
    );

  if (aUserRequestedConfig.EndPoint) {
    userConfigIsValid =
      userConfigIsValid &&
      validateRequiredInputField('EndPoint', aUserRequestedConfig.EndPoint);
  }

  if (!aUserRequestedConfig.AuthType) {
    aUserRequestedConfig.AuthType = 'None';
  }

  userConfigIsValid =
    userConfigIsValid &&
    validateRequiredInputField(
      'AuthType',
      aUserRequestedConfig.AuthType,
      DPA_INPUT_AUTH_TYPE,
    );

  userConfigIsValid =
    userConfigIsValid && validateUsername(aUserRequestedConfig);

  // DeploymentMode
  if (!aUserRequestedConfig.DeploymentMode)
    aUserRequestedConfig.DeploymentMode = 'Internal';
  userConfigIsValid =
    userConfigIsValid &&
    validateRequiredInputField(
      'DeploymentMode',
      aUserRequestedConfig.DeploymentMode,
      DPA_INPUT_DEPLOYMENT_MODE,
    );

  // ResponseType
  if (!aUserRequestedConfig.ResponseType)
    aUserRequestedConfig.ResponseType = 'extended';
  userConfigIsValid =
    userConfigIsValid &&
    validateRequiredInputField(
      'ResponseType',
      aUserRequestedConfig.ResponseType,
      DPA_INPUT_RESPONSE_TYPE,
    );

  // jsFramework
  if (!aUserRequestedConfig.jsFramework)
    aUserRequestedConfig.jsFramework = 'None';
  userConfigIsValid =
    userConfigIsValid &&
    validateRequiredInputField(
      'jsFramework',
      aUserRequestedConfig.jsFramework,
      DPA_INPUT_JS_FRAMEWORK,
    );

  if (!aUserRequestedConfig.PublisherID) {
    aUserRequestedConfig.PublisherID = aUserRequestedConfig.Username;
  }
  return userConfigIsValid;
};

export const validateMessageBody = (aMessageBodyMap = {}) => {
  let messageIsValid = true;

  messageIsValid =
    messageIsValid &&
    validateRequiredInputField(
      'ApplicationCode',
      aMessageBodyMap.eventSourceAppCode,
    );

  messageIsValid =
    messageIsValid &&
    validateRequiredInputField(
      'ApplicationVersion',
      aMessageBodyMap.eventSourceAppVersion,
    );

  messageIsValid =
    messageIsValid &&
    validateRequiredInputField(
      'InteractionCode',
      aMessageBodyMap.interactionCode,
    );

  // TODO: verify that this should be required and is set when API is called
  // messageIsValid = messageIsValid && validateRequiredInputField('AssetLoadID', aMessageBodyMap.assetLoadID);

  messageIsValid =
    messageIsValid &&
    validateRequiredInputField(
      'DateCapturedUTC',
      aMessageBodyMap.dateCapturedUTC,
    );

  // TODO: enable after time offset is recorded instead of the date
  // messageIsValid = messageIsValid && validateRequiredInputField('DateCapturedLocal', aMessageBodyMap.dateCapturedLocal);

  return messageIsValid;
};

const validateUsername = (config) => {
  return isUnAuthenticatedRoute(config?.EndPoint)
    ? config.AwaitUserName
      ? true
      : validateRequiredInputField('Username', config.Username)
    : isAuthenticatedRoute(config?.EndPoint)
    ? true
    : validateRequiredInputField('Username', config.Username);
};

export const isUnAuthenticatedRoute = (EndPoint) => {
  return (
    EndPoint &&
    (isPublicUnAuthenticatedRoute(EndPoint) ||
      isMetricsUnAuthenticatedRoute(EndPoint))
  );
};

export const isAuthenticatedRoute = (EndPoint) => {
  return (
    (EndPoint &&
      (EndPoint.includes('/logging') || EndPoint.includes('/ilogging'))) ||
    EndPoint.includes('/slogging')
  );
};

export const isPublicUnAuthenticatedRoute = (EndPoint) => {
  return EndPoint && EndPoint.includes('/publicUnauthenticated');
};

export const isSiteminderAuthenticatedRoute = (EndPoint) => {
  return EndPoint && EndPoint.includes('/slogging');
};

export const isMetricsUnAuthenticatedRoute = (EndPoint) => {
  return EndPoint && EndPoint.includes('/ulogging');
};

export const isProd = (EndPoint) => {
  return (
    EndPoint &&
    !EndPoint.includes('-test') &&
    !EndPoint.includes('-dev') &&
    !EndPoint.includes('-uat') &&
    !EndPoint.includes('-local') &&
    !EndPoint.includes('.test') &&
    !EndPoint.includes('.dev') &&
    !EndPoint.includes('.uat') &&
    !EndPoint.includes('-d1.') &&
    !EndPoint.includes('-d2.') &&
    !EndPoint.includes('-d3.') &&
    !EndPoint.includes('-d4.') &&
    !EndPoint.includes('-d5.') &&
    !EndPoint.includes('-d6.') &&
    !EndPoint.includes('-d7.') &&
    !EndPoint.includes('-d8.') &&
    !EndPoint.includes('-d9.') &&
    !EndPoint.includes('-t1.') &&
    !EndPoint.includes('-t2.') &&
    !EndPoint.includes('-t3.') &&
    !EndPoint.includes('-t4.') &&
    !EndPoint.includes('-t5.') &&
    !EndPoint.includes('-t6.') &&
    !EndPoint.includes('-t7.') &&
    !EndPoint.includes('-t8.') &&
    !EndPoint.includes('-t9.')
  );
};

export const isInternal = (EndPoint) => {
  return (
    EndPoint &&
    (EndPoint.includes('jpmchase.net') || EndPoint.includes('adobecqms.net'))
  );
};

import { searchForOriginId, checkForAutoCapture } from '../algorithms';

import {
  filterCustomData,
  removeEmptyArgs,
  sanitizeUserInput,
} from '../../utils/functions';
import { sendEventUnfiltered } from '../../publish/sendCapturedEvents';
import { DPA_FORM_RESET } from '../../utils/constants';

export function resetEventHandler(aResetEvent) {
  if (checkForAutoCapture(aResetEvent)) return;

  let args = {};
  let messageBodyMap = {};
  messageBodyMap.eventTimeStamp = new Date();
  let interaction_code = DPA_FORM_RESET;
  args['event.execute.tm_since_load'] = Math.round(aResetEvent.timeStamp);
  args['event.execute.origin_type'] = aResetEvent.type;
  args['event.execute.origin_id'] = searchForOriginId({ event: aResetEvent });
  args['event.execute.tm_since_load'] = aResetEvent.tm_sinceload;
  if (aResetEvent?.form) {
    args['event.execute.form_id'] = sanitizeUserInput(aResetEvent.form?.id);
  }
  let custom_data = filterCustomData(aResetEvent.target.dataset);
  args = removeEmptyArgs(args);

  messageBodyMap = {
    interactionCode: interaction_code,
  };

  sendEventUnfiltered(aResetEvent, 'Reset event handler', messageBodyMap, {
    ...args,
    ...custom_data,
  });
}

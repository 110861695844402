import { manageUserSession, manageAssetLoadSession } from './sessionManager';
import {
  getSession,
  getPageSession,
  setPageSession,
} from '../external_modules/analytics-util-session';
import { initInstrumentedTracking } from './customBehaviorEventHandlers';
import onRouteChange from '../external_modules/analytics-utils/onRouteChange';
import {
  transmitImmediatelyOnBeacon,
  // transmitOnScheduler,
} from '../publish/sendCapturedEvents';

import { filterCustomData } from '../utils/functions';

import {
  DPA_PAGE_SHOW,
  DPA_PAGE_LOAD,
  DPA_PAGE_HIDE,
  DPA_PAGE_DESTROY,
  DPA_SPA_PAGE_CHANGE,
  // DPA_FRAME_LOAD,
  // DPA_FRAME_HIDE,
  // DPA_FRAME_DESTROY,
  // DPA_FRAME_SHOW,
  PAGE_BEFORE_UNLOAD,
  PAGE_UNLOAD,
  APPLICATION_LAUNCH,
} from '../utils/constants';

import {
  CHANGE,
  PAGE_SHOW,
  PAGE_HIDE,
  PAGE_VISIBILITY,
  SUBMIT,
  RESET,
  CLICK,
} from '../utils/constants';

import { sendEventUnfiltered } from '../publish/sendCapturedEvents';

import {
  setUserSession,
  getConfig,
  sdkBehaviorConfig,
  getActivityTime,
  resetActivityCounters,
} from '../config/sdkConfig';

import { clickEventHandler } from './native_event_handlers/clickEventHandler';
import { changeEventHandler } from './native_event_handlers/changeEventHandler';
import { submitEventHandler } from './native_event_handlers/submitEventHandler';
import { resetEventHandler } from './native_event_handlers/resetEventHandler';

import Log from '../utils/logger';
import * as packageJson from '../../package.json';

const translateNavigationType = (navCode) => {
  let navVal = navCode;
  switch (navCode) {
    case 0: {
      navVal = 'Navigate';
      break;
    }

    case 1: {
      navVal = 'Reload';
      break;
    }

    case 2: {
      navVal = 'Back_Navigate';
      break;
    }
    default: {
      break;
    }
  }
  return navVal;
};

// class-based mutation listeners: currentApp.isListeningForMutations
const enableRegistrationNonDefaultEventListeners = () => {
  console.warn('non-default event listeners are enabled');
  document.addEventListener('readystatechange', initInstrumentedTracking, true);
};

const disableRegistrationNonDefaultEventListeners = () => {
  document.removeEventListener(
    'readystatechange',
    initInstrumentedTracking,
    true,
  );
};

let pageEndNotReported = true;

function resetActivityForReportingFlag() {
  pageEndNotReported = true;
}

function getActivityForReporting(reportAlways = false) {
  let eventAttributesMap = null;
  if (pageEndNotReported || reportAlways) {
    const { active: accumulatedActiveTime, idle: accumulatedIdleTime } =
      getActivityTime();

    eventAttributesMap = {
      'asset.user.active_time': accumulatedActiveTime,
      'asset.user.idle_time': accumulatedIdleTime,
    };
    pageEndNotReported = reportAlways ? pageEndNotReported : false;
  }
  return eventAttributesMap;
}

const enableCaptureNavigationEventListeners = () => {
  let currentApp = getConfig();

  window.addEventListener(PAGE_SHOW, pageShowEventHandler);
  window.addEventListener(PAGE_UNLOAD, pageUnloadEventListener);
  window.addEventListener(PAGE_BEFORE_UNLOAD, pageBeforeUnloadEventListener);
  window.addEventListener(PAGE_HIDE, pageHideEventHandler);
  window.addEventListener(PAGE_VISIBILITY, pageVisibilityEventHandler);
  if (currentApp.isReact) {
    addRouteChangeEventListener(reactRouteChangeEventHandler);
  }
};

const disableCaptureNavigationEventListeners = () => {
  let currentApp = getConfig();
  window.removeEventListener(PAGE_SHOW, pageShowEventHandler);
  window.removeEventListener(PAGE_VISIBILITY, pageVisibilityEventHandler);
  window.removeEventListener(PAGE_HIDE, pageHideEventHandler);
  window.removeEventListener(PAGE_UNLOAD, pageUnloadEventListener);
  window.removeEventListener(PAGE_BEFORE_UNLOAD, pageBeforeUnloadEventListener);
  if (currentApp.isReact) {
    removeRouteChangeEventListener(reactRouteChangeEventHandler);
  }
};

const enableAutoCaptureEventListeners = () => {
  document.addEventListener(CHANGE, changeEventHandler);
  document.addEventListener(CLICK, clickEventHandler);
  document.addEventListener(SUBMIT, submitEventHandler);
  document.addEventListener(RESET, resetEventHandler);
};

const disableAutoCaptureEventListeners = () => {
  disableCaptureNavigationEventListeners();
  document.removeEventListener(CHANGE, changeEventHandler);
  document.removeEventListener(CLICK, clickEventHandler);
  document.removeEventListener(SUBMIT, submitEventHandler);
  document.removeEventListener(RESET, resetEventHandler);
};

const pageHideEventHandler = (aPageHideEvent) => {
  if (aPageHideEvent.persisted) {
    pagePreserveToCacheEventHandler(aPageHideEvent);
  } else {
    // this is a genuine page load event
    pageDestroyEventHandler(aPageHideEvent);
  }
};

// COMPONENTID //
const pageUnloadEventListener = (aPageUnloadEvent) => {
  const activityTimeAttributesToReport = getActivityForReporting();
  if (activityTimeAttributesToReport) {
    const messageBodyMap = {
      interactionCode: DPA_PAGE_DESTROY,
      interactionDescriptor: 'unload',
    };
    sendEventUnfiltered(aPageUnloadEvent, null, messageBodyMap, {
      ...activityTimeAttributesToReport,
    });

    transmitImmediatelyOnBeacon();
  }
};

const pageBeforeUnloadEventListener = (aPageBeforeUnloadEvent) => {
  const activityTimeAttributesToReport = getActivityForReporting();
  if (activityTimeAttributesToReport) {
    const messageBodyMap = {
      interactionCode: DPA_PAGE_DESTROY,
      interactionDescriptor: 'before unload',
    };
    sendEventUnfiltered(aPageBeforeUnloadEvent, null, messageBodyMap, {
      ...activityTimeAttributesToReport,
    });
    transmitImmediatelyOnBeacon();
  }
};

const pageVisibilityEventHandler = (aPageVisibilityEvent) => {
  if (document.visibilityState === 'hidden') {
    pageVisibleToHidden(aPageVisibilityEvent);
  } else {
    pageHiddenToVisible(aPageVisibilityEvent);
  }
};

// 2 new ƒunctions to support pageVisibilityEventHandler

// - 1 pageVisibleToHidden
const pageVisibleToHidden = (aPageVisibilityEvent) => {
  let activityTimeAttributesToReport = getActivityForReporting();
  activityTimeAttributesToReport =
    activityTimeAttributesToReport !== null
      ? activityTimeAttributesToReport
      : {};
  const messageBodyMap = {
    interactionCode: DPA_PAGE_HIDE,
    interactionDescriptor: 'Hidden',
  };
  sendEventUnfiltered(aPageVisibilityEvent, null, messageBodyMap, {
    ...activityTimeAttributesToReport,
  });
};

// - 2 pageHiddenToVisible
const pageHiddenToVisible = (aPageVisibilityEvent) => {
  resetActivityForReportingFlag();
  const messageBodyMap = {
    interactionCode: DPA_PAGE_SHOW,
    interactionDescriptor: 'Visible',
  };
  sendEventUnfiltered(aPageVisibilityEvent, DPA_PAGE_SHOW, messageBodyMap, {});
};

const pagePreserveToCacheEventHandler = (anEvent) => {
  let activityTimeAttributesToReport = getActivityForReporting(true);
  const messageBodyMap = {
    interactionCode: DPA_PAGE_HIDE,
    interactionDescriptor: 'Cached',
  };
  sendEventUnfiltered(anEvent, DPA_PAGE_HIDE, messageBodyMap, {
    ...activityTimeAttributesToReport,
  });
};

const pageDestroyEventHandler = (anEvent) => {
  let config = getConfig();
  const activityTimeAttributesToReport = getActivityForReporting();
  if (activityTimeAttributesToReport) {
    const messageBodyMap = {
      interactionCode: DPA_PAGE_DESTROY,
      interactionDescriptor: 'destroy',
    };
    sendEventUnfiltered(anEvent, DPA_PAGE_DESTROY, messageBodyMap, {
      ...activityTimeAttributesToReport,
    });
    transmitImmediatelyOnBeacon();
  }
};

// navigate away then use back button
const pageShowEventHandler = (anEvent) => {
  //Remove the duplicate SPA_PAGE_CHANGE event on a new application launch
  if (sdkBehaviorConfig && sdkBehaviorConfig.isaNewApplicationLaunch) {
    sdkBehaviorConfig.isaNewApplicationLaunch = false;
    return;
  }
  if (anEvent.persisted) {
    pageLoadFromCacheEventHandler(anEvent);
  } else {
    // ** this is a genuine page load event **
    pageLoadFromServerEventHandler(anEvent);
  }
};

const pageLoadFromCacheEventHandler = (anEvent) => {
  let config = getConfig();
  // let currentPageURL = anEvent.target.location.pathname;
  let customData = filterCustomData(anEvent.target?.body.dataset);
  let eventAttributesMap = {};

  const messageBodyMap = {
    interactionCode: config.isReact ? DPA_SPA_PAGE_CHANGE : DPA_PAGE_SHOW,
  };

  sendEventUnfiltered(anEvent, DPA_PAGE_SHOW, messageBodyMap, {
    ...eventAttributesMap,
    ...customData,
  });
};

const pageLoadFromServerEventHandler = (anEvent) => {
  let config = getConfig();
  const assetLoad = {};
  loadTelemetry(assetLoad);
  let customData = filterCustomData(anEvent.target?.body.dataset);
  let eventAttributesMap = {
    'protocol.capture.tool_name': 'js-sdk',
    'protocol.capture.tool_version': packageJson.version,
  };

  const messageBodyMap = {
    interactionCode: config.isReact ? DPA_SPA_PAGE_CHANGE : DPA_PAGE_LOAD,
  };

  sendEventUnfiltered(anEvent, DPA_PAGE_LOAD, messageBodyMap, {
    ...assetLoad,
    ...eventAttributesMap,
    ...customData,
  });
};

export function loadTelemetry(assetLoad) {
  let loadTime = window.performance.timing;
  let referrer_unload = Math.min(
    0,
    loadTime?.unloadEventEnd - loadTime?.unloadEventStart,
  );
  referrer_unload != 0
    ? (assetLoad['telemetry.asset.referrer_unload'] = referrer_unload)
    : null;
  let redirect_complete = Math.max(
    0,
    loadTime?.redirectEnd - loadTime?.redirectStart,
  );
  redirect_complete != 0
    ? (assetLoad['telemetry.asset.redirect_complete'] = redirect_complete)
    : null;
  let dns_lookup = Math.max(
    0,
    loadTime?.domainLookupEnd - loadTime?.domainLookupStart,
  );
  dns_lookup != 0
    ? (assetLoad['telemetry.asset.dns_lookup'] = dns_lookup)
    : null;
  let connection_open = Math.max(
    0,
    loadTime?.connectEnd - loadTime?.connectStart,
  );
  connection_open != 0
    ? (assetLoad['telemetry.asset.connection_open'] = connection_open)
    : null;
  if (loadTime?.secureConnectionStart > 0) {
    assetLoad['telemetry.asset.ssl_connection_open'] = Math.max(
      0,
      loadTime?.connectEnd - loadTime?.secureConnectionStart,
    );
  }
  let dom_load = Math.max(
    0,
    loadTime?.domContentLoadedEventEnd - loadTime?.domContentLoadedEventStart,
  );
  dom_load > 0 ? (assetLoad['telemetry.asset.dom_load'] = dom_load) : null;
  let dom_render = Math.max(
    0,
    loadTime?.domComplete - loadTime?.domInteractive,
  );
  dom_render != 0
    ? (assetLoad['telemetry.asset.dom_render'] = dom_render)
    : null;
  let asset_load = Math.max(0, loadTime?.loadEventEnd - loadTime?.fetchStart);
  asset_load != 0
    ? (assetLoad['telemetry.asset.asset_load'] = asset_load)
    : null;
  let asset_render = Math.max(
    0,
    loadTime?.loadEventEnd - loadTime?.loadEventStart,
  );
  asset_render != 0
    ? (assetLoad['telemetry.asset.asset_render'] = asset_render)
    : null;
  let ttfb = Math.max(0, loadTime?.responseStart - loadTime?.fetchStart);
  ttfb != 0 ? (assetLoad['telemetry.asset.ttfb'] = ttfb) : null;
  let ttfb_to_dom = Math.max(
    0,
    loadTime?.domInteractive - loadTime?.fetchStart,
  );
  ttfb_to_dom != 0
    ? (assetLoad['telemetry.asset.ttfb_to_dom'] = ttfb_to_dom)
    : null;
  assetLoad['asset.info.navigation_type'] = translateNavigationType(
    window.performance.navigation?.type,
  );
}

const reactRouteChangeEventHandler = (
  aTargetPathname = '',
  anEventAttributesMap = {},
) => {
  // removed to allow spaPageChange events 9/19/24
  // if (sdkBehaviorConfig && sdkBehaviorConfig.isaNewApplicationLaunchReact) {
  //   sdkBehaviorConfig.isaNewApplicationLaunchReact = false;
  //   return;
  // }
  let syntheticTargetPathname = aTargetPathname;
  // NOTE: below added to screen out full urls in JPMM login pages 1/18/24
  // expected valid input is '' or url w/o domain
  // we get a full domain on JPMM login so correcting it here
  try {
    let aValidUrl = new URL(aTargetPathName);
    syntheticTargetPathname =
      aValidUrl.origin + aValidUrl.pathname + aValidUrl.hash + aValidUrl.search;
  } catch (err) {
    // catch means input was valid - no need to fix it
    syntheticTargetPathname = document.location.origin + aTargetPathname;
  }

  let userSession = manageUserSession();

  // If session.isNew return
  // this will be captured by new ApplicationLaunch
  if (userSession.isNew) {
    return;
  }

  resetApplicationLifeCycle(DPA_SPA_PAGE_CHANGE, syntheticTargetPathname, null);
};

// new Application change //
export function componentLoad(config) {
  resetApplicationLifeCycle(APPLICATION_LAUNCH, null, config);
}

function resetApplicationLifeCycle(
  pageChangeEvent,
  syntheticTargetPathname,
  newconfig,
) {
  manageUserSession();
  let activityTimeAttributesToReport = getActivityForReporting(true);
  const destroyMessageBodyMap = {
    interactionCode: DPA_PAGE_DESTROY,
    interactionDescriptor: 'spa',
  };

  sendEventUnfiltered(null, DPA_PAGE_DESTROY, destroyMessageBodyMap, {
    ...activityTimeAttributesToReport,
  });

  resetActivityCounters();

  let config = getConfig();

  if (newconfig?.ApplicationCode) {
    config.ApplicationCode = newconfig.ApplicationCode;
  }
  if (newconfig?.ApplicationVersion) {
    config.ApplicationVersion = newconfig.ApplicationVersion;
  }

  const newAssetLoadSession = setPageSession();

  manageAssetLoadSession(newAssetLoadSession);

  let customData = filterCustomData(window.document.body.dataset);

  let eventAttributesMap = {
    ...customData,
  };

  let messageBodyMap = {
    interactionCode: pageChangeEvent,
  };

  if (syntheticTargetPathname) {
    messageBodyMap = {
      ...messageBodyMap,
      ...{ assetAddress: syntheticTargetPathname },
    };
  }

  sendEventUnfiltered(
    null,
    pageChangeEvent,
    messageBodyMap,
    eventAttributesMap,
  );
}

export function addRouteChangeEventListener(callback) {
  onRouteChange(callback);
}

export function removeRouteChangeEventListener(callback) {
  onRouteChange(callback);
}

export let initializeAnalyticsEventListeners = () => {
  let currentApp = getConfig();
  if (currentApp.isConfigValid) {
    if (currentApp.isCaptureEnabled) {
      if (currentApp.enableCapture) {
        enableCaptureNavigationEventListeners();
      }
      if (currentApp.enableAutoCapture) {
        enableAutoCaptureEventListeners();
      }
      if (currentApp.isListeningForMutations) {
        enableRegistrationNonDefaultEventListeners();
      }
    } else {
      // TODO: is not active then remove all event listeners
      disableRegistrationNonDefaultEventListeners();
      disableAutoCaptureEventListeners();
    }
  } else {
    Log.error(`SDK configuration is not valid, discontinuing execution`);
  }
};

export const exportedForTesting = {
  translateNavigationType,
  getActivityForReporting,
  enableCaptureNavigationEventListeners,
  disableCaptureNavigationEventListeners,
  enableAutoCaptureEventListeners,
  disableAutoCaptureEventListeners,
  pageHideEventHandler,
  pageBeforeUnloadEventListener,
  pageVisibilityEventHandler,
  pageVisibleToHidden,
  pageHiddenToVisible,
  pagePreserveToCacheEventHandler,
  pageDestroyEventHandler,
  pageShowEventHandler,
  pageLoadFromCacheEventHandler,
  pageLoadFromServerEventHandler,
  reactRouteChangeEventHandler,
  pageUnloadEventListener,
};

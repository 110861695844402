import { VALIDLEGACYINTERACTIONCODES } from '../utils/constants';
import Log from './logger';

export const toTitleCase = (str) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.substr(1);
  } else {
    return null;
  }
};

export const sanitizeUserInput = (aUserText = '') => {
  aUserText = aUserText.replace(' ', '_').toLowerCase();
  aUserText = aUserText.replace(/\W+/i, '');
  return aUserText;
};

export const sanitizeOriginID = (aUserText = '') => {
  aUserText = aUserText.replace(' ', '_').toLowerCase();
  aUserText = aUserText.replace(/[^A-Za-z0-9\-_]/g, '');
  return aUserText;
};

export const preserveUserInput = (aUserText = '') => {
  return encodeURIComponent(aUserText);
};

export const validateInteractionCode = (
  aProposedInteractionCode,
  aDefaultInteractionCode = 'EventCapture',
) => {
  // TODO:: implement dynamic loading of the proposed interaction codes and then enable this filter.
  // Until then we allow every interaction code sent and reject it at the service.
  return aProposedInteractionCode;
  /*  if (VALIDLEGACYINTERACTIONCODES.indexOf(aProposedInteractionCode) !== -1) {
    return aProposedInteractionCode;
  } else {
    Log.debug(
      `Interaction code validation failed using ${aProposedInteractionCode}, defaulting to ${aDefaultInteractionCode}`,
    );
    return aDefaultInteractionCode;
  }*/
};

const isCharUpperCase = (char) => char === char.toUpperCase();

/**
 * Overrides native dataset name camel case conversion
 * inserts . after capital letters then lowercases them
 * @param {object} dataset
 * @returns {object} modified dataset without dpa and with dots separator
 * Conforms to v3 spec: [a-zA-Z0-9+=;:#}{][)(.,|_-]{0,64}
 * @todo test santized key
 */
export const filterCustomData = (dataset) => {
  let dataArgs = {};
  if (dataset) {
    for (let [key, value] of Object.entries(dataset)) {
      let possibleDpaKey = key.toString().toLowerCase();
      let modifiedDpaKey = '';
      if (possibleDpaKey.startsWith('dpa')) {
        if (possibleDpaKey.includes('.')) {
          modifiedDpaKey = possibleDpaKey.slice(3).substring(0, 64);
        } else {
          let modifiedKey = [];
          for (let char of key) {
            if (isCharUpperCase(char) && char !== '_') {
              modifiedKey.push('.');
            }
            modifiedKey.push(char.toLowerCase());
          }
          modifiedDpaKey = modifiedKey.join('').slice(4);
        }
        modifiedDpaKey = modifiedDpaKey.replace(/[^a-z0-9-_:#.]/gi, '');
        if (modifiedDpaKey.length > 4) {
          dataArgs[modifiedDpaKey] = value.substring(0, 250);
        }
      }
    }
  }
  return removeEmptyArgs(dataArgs);
};

/**
 * @param {object} args
 * @returns {object} with empty pairs removed
 */
export const removeEmptyArgs = (args) => {
  let filteredArgs = {};
  for (let [key, value] of Object.entries(args)) {
    if (value) {
      if (!(typeof value === 'object' || typeof value === 'function')) {
        filteredArgs[key] = value;
      } else {
        filteredArgs[key] = `Removed unsupported: ${typeof value}`;
      }
    }
  }
  return filteredArgs;
};

/**
 * @param {object} ob  The object to flatten
 * @param prefix String (Optional)  The prefix to add before each key, also used for recursion
 **/
export function flattenObject(ob, prefix = false, result = null) {
  result = result || {};

  if (prefix) {
    Log.warn(`Multi-dimensional objects are not allowed, flattening ${prefix}`);
  }

  // Preserve empty objects and arrays, they are lost otherwise
  if (
    prefix &&
    typeof ob === 'object' &&
    ob !== null &&
    Object.keys(ob).length === 0
  ) {
    result[prefix] = Array.isArray(ob) ? [] : {};
    return result;
  }

  prefix = prefix ? prefix + '.' : '';

  for (const i in ob) {
    if (Object.prototype.hasOwnProperty.call(ob, i)) {
      if (typeof ob[i] === 'object' && ob[i] !== null) {
        // Recursion on deeper objects
        flattenObject(ob[i], prefix + i, result);
      } else {
        result[prefix + i] = ob[i];
      }
    }
  }
  return result;
}

/**
 * currently for custom behavior event handlers
 * @param {Element} aDOMElement
 * @returns class name
 */
export function extractClassName(aDOMElement) {
  let extractedClassName = '';
  if (typeof aDOMElement.className === 'string') {
    extractedClassName = aDOMElement.className;
  } else if (typeof aDOMElement.className === 'svganimatedstring') {
    extractedClassName = aDOMElement.className.toString();
  } else {
    extractedClassName = '';
  }
  return extractedClassName;
}

// export function getCookie(aCookieName) {
//   let value = `; ${document.cookie}`;
//   let parts = value.split(`; ${aCookieName}=`);
//   if (parts.length === 2) return parts.pop().split(';').shift();
// }

import { filterAndSendJSEvents } from '../publish/sendCapturedEvents';
import { filterCustomData, removeEmptyArgs } from '../utils/functions';
import { searchForOriginId } from '../analytics/algorithms';
import { getConfig, getAssetLoadSession } from '../config/sdkConfig';

import Log from '../utils/logger';
import { EMPTY } from '../utils/constants';

export function capture() {
  const customArgs = [...arguments];
  const len = customArgs.length;
  const assetLoadSessionObject = getAssetLoadSession();
  const defaultAssetLoadId = assetLoadSessionObject?.id
    ? assetLoadSessionObject.id
    : EMPTY;
  const anEvent = new Event('API');
  switch (len) {
    // export interface AnalyticsCapture {
    //   BehaviorCode: string;
    //   BehaviorDescriptor?: string;
    //   EventAttributes?: object;
    //   ApplicationComponentCode?: string;
    //   HostedBy?: string; // (optional)
    // }

    // capture ( {
    // 'BehaviorCode' : "Application.Load",
    // ...
    // } );

    case 1: {
      const captureInputParameters = customArgs[0];
      const aUserSpecifiedAssetLoadID = captureInputParameters['AssetLoadID'];
      const anAppComponentCode =
        captureInputParameters['ApplicationComponentCode'];
      const aUserInteractionCode = captureInputParameters['BehaviorCode'];
      const aDescriptor = captureInputParameters['BehaviorDescriptor'];
      const anEventAttributesMap = captureInputParameters['EventAttributes'];
      const aHostedBy = captureInputParameters['HostedBy'];
      executeCapture(
        anEvent,
        anAppComponentCode,
        aUserSpecifiedAssetLoadID,
        aUserInteractionCode,
        aDescriptor,
        anEventAttributesMap,
      );
      break;
    }
    case 2: {
      const userInteractionCode = customArgs[0];
      const descriptor = customArgs[1];
      executeCapture(
        anEvent,
        null,
        defaultAssetLoadId,
        userInteractionCode,
        descriptor,
        {},
      );
      break;
    }
    case 3: {
      if (typeof customArgs[2] === 'string') {
        // e.g. dpAnalytics.capture(anAppComponentCode, aUserInteractionCode, aDescriptor);
        const anAppComponentCode = customArgs[0];
        const aUserInteractionCode = customArgs[1];
        const aDescriptor = customArgs[2];
        executeCapture(
          anEvent,
          anAppComponentCode,
          defaultAssetLoadId,
          aUserInteractionCode,
          aDescriptor,
          {},
        );
      } else {
        // dpAnalytics.capture(aUserInteractionCode, aDescriptor, {"key1":"value1", "key2":"value2",});
        const aUserInteractionCode = customArgs[0];
        const aDescriptor = customArgs[1];
        const anEventAttributesMap = customArgs[2];
        executeCapture(
          anEvent,
          null,
          defaultAssetLoadId,
          aUserInteractionCode,
          aDescriptor,
          anEventAttributesMap,
        );
      }
      break;
    }
    case 4: {
      // dpAnalytics.capture(anAppComponentCode, aUserInteractionCode, aDescriptor, {"key1":"value1", "key2":"value2",});
      const anAppComponentCode = customArgs[0];
      const aUserInteractionCode = customArgs[1];
      const aDescriptor = customArgs[2];
      const anEventAttributesMap = customArgs[3];
      executeCapture(
        anEvent,
        anAppComponentCode,
        defaultAssetLoadId,
        aUserInteractionCode,
        aDescriptor,
        anEventAttributesMap,
      );
      break;
    }
    case 5: {
      // dpAnalytics.capture(anAssetLoadId, anAppComponentCode, assetLoadId, aUserInteractionCode, aDescriptor, {"key1":"value1", "key2":"value2",});
      const aUserSpecifiedAssetLoadID = customArgs[0];
      const anAppComponentCode = customArgs[1];
      const aUserInteractionCode = customArgs[2];
      const aDescriptor = customArgs[3];
      const anEventAttributesMap = customArgs[4];
      executeCapture(
        anEvent,
        anAppComponentCode,
        aUserSpecifiedAssetLoadID,
        aUserInteractionCode,
        aDescriptor,
        anEventAttributesMap,
      );
      break;
    }
    default: {
      Log.critical(
        `You passed ${
          customArgs.length < 2 ? 'too few' : 'too many'
        } arguments`,
      );
      break;
    }
  }
}

const executeCapture = (
  anEvent,
  anApplicationComponentCode,
  anAssetLoadId,
  aUserInteractionCode,
  aDescriptor,
  anEventAttributesMap = {},
) => {
  let opStatus = false;
  let config = getConfig();

  if (config.isConfigValid) {
    const appCustomDataset = anEvent?.target
      ? anEvent.target?.dataset
        ? anEvent.target.dataset
        : {}
      : {};

    let filteredCustomData = filterCustomData(appCustomDataset);
    let validatedCustomData = removeEmptyArgs(filteredCustomData);

    const eventOriginId = anEvent ? searchForOriginId({ event: anEvent }) : '';
    anEventAttributesMap.origin_id = eventOriginId;
    let eventAttributesMap = {
      ...anEventAttributesMap,
      ...validatedCustomData,
    };

    let messageBodyMap = {
      interactionCode: aUserInteractionCode,
      assetLoadID: anAssetLoadId,
      interactionDescriptor: `${aDescriptor}`,
      applicationComponentCode: anApplicationComponentCode,
    };

    opStatus = filterAndSendJSEvents(
      anEvent,
      aUserInteractionCode,
      messageBodyMap,
      eventAttributesMap,
    );

    // console.log('opStatus', opStatus);
  } else {
    Log.debug(`API did not send. opStatus: ${opStatus}`);
  }
  return opStatus;
};

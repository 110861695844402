import Log from '../../utils/logger';

/**
 * Based on docs: https://bitbucketdc.jpmchase.net/projects/JPMMMETRICS/repos/dps-analytics-js/browse?at=refs%2Fheads%2Fdevelop
 *
 * anEnv - type of execution environment; valid values: PROD, UAT
 * aDeploymentType - valid values: Internal (within JPMC network), External (public internet)
 * anAuthType - type of authentication used; valid values: Janus, IDA, None
 * aPlatfrom - see docs for valid values:
 *            https://confluence.prod.aws.jpmchase.net/confluence/display/DGP/Identifying+Event-Originating+Execution+Environment
 *
 * Return:
 * URL to correct data-collection end-point based on input parameters, or null if combination of parameters is not valid
 */
export const generateEndPointURL = (
  anEnv,
  aDeploymentType,
  anAuthType,
  aPlatform = '',
  SERVICE_VERSION = 'v3',
) => {
  const SERVICE_NAME_PREFIX = 'metricsservice';
  let generatedURI = null;

  // default all non-PROD values to become 'UAT'
  if ('PROD' !== anEnv) {
    anEnv = 'UAT';
  }

  // default all non-External values to become 'Internal'
  if ('External' !== aDeploymentType) {
    aDeploymentType = 'Internal';
  }

  // take auth as central divisor
  switch (anAuthType) {
    case 'Janus': {
      const SERVICE_NAME = 'logging';
      if ('External' === aDeploymentType) {
        const DOMAIN =
          'PROD' === anEnv
            ? 'https://markets.jpmorgan.com'
            : 'https://markets-uat.jpmorgan.com';
        generatedURI = `${DOMAIN}/${SERVICE_NAME_PREFIX}/${SERVICE_VERSION}/${SERVICE_NAME}`;
      } else {
        const DOMAIN =
          'PROD' === anEnv
            ? 'https://metricsservice.gaiacloud.jpmchase.net'
            : 'https://metricsservice.test.gaiacloud.jpmchase.net';
        generatedURI = `${DOMAIN}/${SERVICE_NAME_PREFIX}/${SERVICE_VERSION}/${SERVICE_NAME}`;
      }
      // override URL for special case: running inside of JPMC containers that have built-in back-end communication
      if ('JPMM' === aPlatform || 'DPD' === aPlatform) {
        generatedURI = `/${SERVICE_NAME_PREFIX}/${SERVICE_VERSION}/${SERVICE_NAME}`;
      }
      break;
    }

    // TODO: update the IDA end-points to use new (token-less) end-points
    // current URLs are legacy end-points (still require to pass AuthToken) -- new end-points are coming EO August 2022
    case 'IDA': {
      const SERVICE_NAME = 'ilogging';
      if ('Internal' === aDeploymentType) {
        const DOMAIN =
          'PROD' === anEnv
            ? 'https://dps-analytics-gateway-ida.jpmchase.net'
            : 'https://dps-analytics-gateway-ida-test.jpmchase.net';
        generatedURI = `${DOMAIN}/${SERVICE_VERSION}/${SERVICE_NAME}`;
      } else {
        Log.error(
          'Invalid Auth for specified Deployment: ',
          anAuthType,
          aDeploymentType,
        );
      }
      break;
    }

    case 'None': {
      const SERVICE_NAME = 'uloggingservice';
      if ('Internal' === aDeploymentType) {
        const DOMAIN =
          'PROD' === anEnv
            ? 'https://metricsservice.gaiacloud.jpmchase.net'
            : 'https://metricsservice.test.gaiacloud.jpmchase.net';
        // TODO: As-of 2022-August these end-points are not part of gateway ("ds" postfix), and are missing 'SERVICE_NAME_PREFIX'
        // this will be fixed in later releases
        generatedURI = `${DOMAIN}/metricsservice/${SERVICE_VERSION}/${SERVICE_NAME}`;
      } else {
        // NOTE: these end-points are extremely guarded - can be used only with pre-approval from owners
        const PUBLIC_SERVICE_NAME = 'v3/publicUnauthenticated';
        const DOMAIN =
          'PROD' === anEnv
            ? 'https://markets.jpmorgan.com'
            : 'https://markets-uat.jpmorgan.com';
        //TODO: these end-points have to be upgraded to v3 (still using v2)
        generatedURI = `${DOMAIN}/${SERVICE_NAME_PREFIX}/${PUBLIC_SERVICE_NAME}`;
      }
      break;
    }

    default: {
      Log.error('Unsupported Auth type specified: ', anAuthType);
      break;
    }
  } //eo switch

  return generatedURI;
};
